import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { ApolloQueryResult, useLazyQuery } from '@apollo/client';

import { useOrganizationContext } from './Organization.context';
import {
    Environments,
    Environments_environments,
    EnvironmentsVariables,
} from 'graphql/queries/generatedTypes/Environments';
import { GET_ENVIRONMENTS } from 'graphql/queries/getEnvironments.query';

const STATUS_READY = 7;

interface EnvironmentsContextState {
    environments: Environments_environments[];
    currentEnvironment: Environments_environments;
    setCurrentEnvironment: (id: string) => void;
    getEnvironments?: () => Promise<ApolloQueryResult<Environments>>;
    isLoadEnvironments: boolean;
}

interface EnvironmentContextProviderProps {
    children?: ReactNode;
}

const LS_ENVIRONMENT_KEY = 'ENVIRONMENT';

const EnvironmentContext = createContext<EnvironmentsContextState>({
    environments: [],
    currentEnvironment: null,
    setCurrentEnvironment: () => null,
    getEnvironments: null,
    isLoadEnvironments: false,
});

const getCurrentEnvironment = (environments: Environments_environments[]): Environments_environments => {
    if (!environments || !environments?.length) {
        return null;
    }
    const savedEnvironmentId: string = JSON.parse(localStorage.getItem(LS_ENVIRONMENT_KEY));
    const environmentIndex = environments.findIndex(({ id }) => id === savedEnvironmentId);

    if (environmentIndex >= 0) {
        return environments[environmentIndex];
    }
    localStorage.setItem(LS_ENVIRONMENT_KEY, JSON.stringify(environments[0]?.id));
    return environments[0];
};

export const useEnvironmentContext = (): EnvironmentsContextState => useContext(EnvironmentContext);

export const EnvironmentContextProvider: React.FC<EnvironmentContextProviderProps> = ({ children }) => {
    const { currentProject } = useOrganizationContext();

    const [currentEnvironment, setCurrentEnv] = useState<Environments_environments>(null);
    const [getEnvironments, { data, networkStatus }] = useLazyQuery<Environments, EnvironmentsVariables>(
        GET_ENVIRONMENTS,
        {
            variables: {
                projectId: currentProject?.id,
            },
            fetchPolicy: 'no-cache',
        }
    );

    useEffect(() => {
        if (currentProject) {
            getEnvironments();
        }
    }, [currentProject]);

    useEffect(() => {
        if (data?.environments) {
            setCurrentEnv(getCurrentEnvironment(data.environments));
        }
    }, [data?.environments]);

    return (
        <EnvironmentContext.Provider
            value={{
                getEnvironments,
                currentEnvironment,
                environments: data?.environments,
                isLoadEnvironments: data && networkStatus === STATUS_READY,
                setCurrentEnvironment: (id) => setCurrentEnv(data?.environments?.find((item) => item?.id === id)),
            }}
        >
            {children}
        </EnvironmentContext.Provider>
    );
};
