import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

import { ReactComponent as CopyIcon } from 'assets/img/files.svg';
import { ReactComponent as MarkIcon } from 'assets/img/mark.svg';

import styles from './Tooltip.module.scss';

const TOOLTIP_OPEN_TIME = 2000;

interface TooltipComponentProps {
    id: string;
    className?: string;
    content?: string;
}

export const TooltipComponent: React.FC<TooltipComponentProps> = ({ id, className, content }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <CopyIcon
                onClick={() => {
                    navigator.clipboard.writeText(content || id);
                    setTimeout(() => setIsOpen(false), TOOLTIP_OPEN_TIME);
                }}
                className={`${styles.icon} ${styles.copyIcon} ${className}`}
                id={`tooltip-${id.replace('.', '-')}`}
            />
            <Tooltip
                target={`tooltip-${id.replace('.', '-')}`}
                innerClassName={styles.tooltipContainer}
                toggle={() => setIsOpen(!isOpen)}
                placement="right"
                hideArrow={true}
                isOpen={isOpen}
                trigger="click"
            >
                <p className={styles.tooltipText}>Copied</p>
                <MarkIcon className={styles.icon} />
            </Tooltip>
        </>
    );
};
