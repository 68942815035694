import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { getDictionary } from 'dictionary';
import { useAuthContext } from 'context/Auth.context';
import { useOrganizationContext } from 'context/Organization.context';
import { translateServerErrors } from 'helpers/translateServerErrors.helper';
import { InputComponent } from '../Input/Input.component';
import { API_KEY_NAME_LENGTH, createApiKeyModalValidation } from './CreateApiKeyModal.validation';
import { ApiKeyType, CreateApiKeyInput } from 'graphql/generatedGlobal.typings';
import { CreateApiKey, CreateApiKeyVariables } from 'graphql/mutations/generatedTypes/CreateApiKey';
import { CREATE_API_KEY } from 'graphql/mutations/createApiKey.mutation';
import { GET_ENVIRONMENTS } from 'graphql/queries/getEnvironments.query';
import { Environments, EnvironmentsVariables } from 'graphql/queries/generatedTypes/Environments';
import { ReactComponent as CloseIcon } from 'assets/img/x-lg.svg';

import modalStyles from 'components/Modal/Modal.module.scss';

interface CreateApiKeyModalComponentProps {
    onClose: () => void;
    onSuccess: () => void;
    isOpen: boolean;
    isHideAdminKey?: boolean;
}

export const CreateApiKeyModalComponent: React.FC<CreateApiKeyModalComponentProps> = ({
    isHideAdminKey = false,
    onSuccess,
    onClose,
    isOpen,
}) => {
    const { organization } = useOrganizationContext();
    const { user } = useAuthContext();

    const [createApiKey, { loading }] = useMutation<CreateApiKey, CreateApiKeyVariables>(CREATE_API_KEY);
    const [getEnvironments, { data: dataEnvironments }] = useLazyQuery<Environments, EnvironmentsVariables>(
        GET_ENVIRONMENTS
    );

    const [isValidateOnChange, setIsValidateOnChange] = useState<boolean>(false);

    const {
        values,
        handleChange,
        isValid,
        touched,
        errors,
        handleBlur,
        dirty,
        handleSubmit,
        resetForm,
        setFieldValue,
        isSubmitting,
    } = useFormik<CreateApiKeyInput>({
        initialValues: {
            organizationId: user?.organizationId,
            type: ApiKeyType.SERVER_SIDE,
            userId: user?.id,
            name: '',
            projectId: '',
            environmentId: '',
        },
        validationSchema: createApiKeyModalValidation,
        onSubmit: (submittingValues) => {
            createApiKey({
                variables: {
                    input: {
                        ...submittingValues,
                        environmentId: submittingValues.environmentId || null,
                    },
                },
            })
                .then(() => onSuccess())
                .catch((error) => translateServerErrors(error));
        },
        validateOnChange: isValidateOnChange,
        validateOnBlur: isValidateOnChange,
    });

    useEffect(() => {
        if (!isOpen) {
            resetForm();
            setIsValidateOnChange(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (values?.projectId) {
            getEnvironments({
                variables: {
                    projectId: values.projectId,
                },
            });
            setFieldValue('environmentId', '');
        }
    }, [values?.projectId]);

    useEffect(() => {
        // Add ability to remove error after submit before next submit action
        if (errors && isSubmitting) {
            setIsValidateOnChange(true);
        }
    }, [errors, isSubmitting]);

    return (
        <Modal size="lg" isOpen={isOpen} cssModule={modalStyles} centered toggle={() => onClose()}>
            <form onSubmit={handleSubmit}>
                <ModalHeader cssModule={modalStyles}>
                    <b>Add API key</b>
                    <CloseIcon className={modalStyles.closeButton} onClick={onClose} />
                </ModalHeader>

                <ModalBody cssModule={modalStyles}>
                    <FormGroup>
                        <Label for="name">
                            <span className={modalStyles.label}>Name </span>
                            (This cannot be changed)
                        </Label>
                        <InputComponent
                            error={touched?.name && errors?.name}
                            maxLength={API_KEY_NAME_LENGTH}
                            onChange={handleChange}
                            value={values?.name}
                            onBlur={handleBlur}
                            name="name"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="projectId" className={modalStyles.label}>
                            Project
                        </Label>
                        <InputComponent
                            error={touched?.projectId && errors?.projectId}
                            value={values?.projectId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="projectId"
                            type="select"
                        >
                            <option value="">...</option>
                            {organization?.projects?.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </InputComponent>
                    </FormGroup>

                    <FormGroup>
                        <Label for="type" className={modalStyles.label}>
                            Type
                        </Label>
                        <InputComponent
                            onChange={(event) => {
                                setFieldValue('type', event.target.value);
                                if (event.target.value === ApiKeyType.ADMIN && values?.environmentId) {
                                    setFieldValue('environmentId', null);
                                }
                            }}
                            error={touched?.type && errors?.type}
                            value={values?.type}
                            onBlur={handleBlur}
                            type="select"
                            name="type"
                        >
                            {Object.keys(ApiKeyType)
                                .filter((key) => {
                                    if (key === ApiKeyType.ADMIN && isHideAdminKey) {
                                        return;
                                    }
                                    return key;
                                })
                                .map((key) => (
                                    <option key={key} value={key}>
                                        {getDictionary(key)}
                                    </option>
                                ))}
                        </InputComponent>
                    </FormGroup>

                    {values?.type !== ApiKeyType.ADMIN && (
                        <FormGroup>
                            <Label for="environmentId" className={modalStyles.label}>
                                Environment
                            </Label>
                            <InputComponent
                                disabled={!(dataEnvironments?.environments?.length && values.projectId)}
                                error={touched?.environmentId && errors?.environmentId}
                                value={values?.environmentId}
                                onChange={handleChange}
                                name="environmentId"
                                onBlur={handleBlur}
                                type="select"
                            >
                                <option value="">...</option>
                                {dataEnvironments?.environments?.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </InputComponent>
                        </FormGroup>
                    )}
                </ModalBody>

                <ModalFooter cssModule={modalStyles}>
                    <div className={modalStyles.wideButtons}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit" color="warning" disabled={!isValid || loading || !dirty}>
                            Create
                        </Button>
                    </div>
                </ModalFooter>
            </form>
        </Modal>
    );
};
