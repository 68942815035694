import React, { useState, useEffect } from 'react';

import { useAuthContext } from 'context/Auth.context';
import { loadScript } from 'helpers/scriptsLoader.helper';
import { token, host, config, SCRIPTS, PRE_CHAT_TEMPLATE, STATUS_OK } from './config.helper';

declare global {
    interface Window {
        fcWidget: any;
        fcSettings: any;
        fcPreChatform: any;
    }
}

export interface FreshdeskChatComponentProps {
    fcPreChatform?: boolean;
}

export const FreshdeskChatComponent: React.FC<FreshdeskChatComponentProps> = ({ fcPreChatform }) => {
    const { user } = useAuthContext();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [restoreID, setRestoreID] = useState<string>(null);

    useEffect(() => {
        const asyncEffect = async () => {
            let scriptsLoaded = true;

            window.fcSettings = {
                token,
                host,
                config,
                locale: 'en',
                externalId: user.id,
                restoreId: restoreID,
                onInit: () => {
                    if (fcPreChatform) {
                        window.fcPreChatform?.fcWidgetInit(PRE_CHAT_TEMPLATE);
                    }

                    if (!restoreID) {
                        window.fcWidget.on('widget:loaded', () => {
                            window.fcWidget.user.get((response) => {
                                if (response.status === STATUS_OK) {
                                    return;
                                }

                                window.fcWidget.user.setProperties({
                                    organizationId: user?.organizationId,
                                    email: user?.email,
                                });

                                window.fcWidget.on('user:created', ({ status, data }) => {
                                    if (status === STATUS_OK && data?.restoreId) {
                                        setRestoreID(data?.restoreId);
                                        // send restoreId to BE
                                    }
                                });
                            });
                        });
                    }
                },
            };

            SCRIPTS.forEach(({ scriptId }) => {
                if (!document.getElementById(scriptId)) {
                    scriptsLoaded = false;
                }
            });

            if (!scriptsLoaded) {
                const promises = [];

                SCRIPTS.forEach(({ scriptId: id, scriptLink }) =>
                    promises.push(loadScript(scriptLink, { id, async: false }))
                );
                await Promise.all(promises);
            }

            setLoaded(true);
        };

        if (process.env.REACT_APP_ENV === 'prod') {
            asyncEffect();
        }
    }, [fcPreChatform, restoreID, user]);

    useEffect(() => {
        if (!loaded) {
            return;
        }
        window.fcWidget?.show();

        return () => {
            window.fcWidget?.hide();
        };
    }, [loaded]);

    return null;
};
