import React, { ChangeEventHandler, FocusEvent } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { FormGroup, Label, Row } from 'reactstrap';

import { getDictionary } from 'dictionary';
import { InputComponent } from '../../Input/Input.component';
import { PostbackStatus } from 'graphql/generatedGlobal.typings';
import { PostbackInput } from '../PostbackModal.types';

import modalStyles from 'components/Modal/Modal.module.scss';

interface ActivityComponentProps {
    values: PostbackInput;
    touched: FormikTouched<PostbackInput>;
    errors: FormikErrors<PostbackInput>;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: (e: FocusEvent) => void;
}

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const ActivityComponent: React.FC<ActivityComponentProps> = ({
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
}) => (
    <Row>
        <b className="mt-3 mb-0 text-decoration-underline">Activity:</b>
        <FormGroup>
            <Label for="status" className={modalStyles.label}>
                Status
            </Label>
            <InputComponent
                error={touched.status && errors.status}
                onChange={handleChange}
                value={values.status}
                onBlur={handleBlur}
                name="status"
                type="select"
            >
                {Object.keys(PostbackStatus)?.map((el) => (
                    <option key={el} value={el}>
                        {getDictionary(el)}
                    </option>
                ))}
            </InputComponent>
        </FormGroup>

        {/*<div className={`${modalStyles.label} d-flex justify-content-start align-items-center`}>*/}
        {/*    <p className={styles.label}>Active Period:</p>*/}

        {/*    {!values.activateDateFrom && (*/}
        {/*        <Button*/}
        {/*            onClick={() =>*/}
        {/*                setFieldValue('activateDateFrom', formatLocalISODateTime(new Date()))*/}
        {/*            }*/}
        {/*            className="mx-3 border-0"*/}
        {/*            color="primary"*/}
        {/*            size="xs"*/}
        {/*            outline*/}
        {/*        >*/}
        {/*            Add Date From*/}
        {/*            <PlusIcon className={styles.icon} />*/}
        {/*        </Button>*/}
        {/*    )}*/}

        {/*    {!values.activateDateTo && (*/}
        {/*        <Button*/}
        {/*            onClick={() => setFieldValue('activateDateTo', formatLocalISODateTime(tomorrow))}*/}
        {/*            className="mx-3 border-0"*/}
        {/*            color="primary"*/}
        {/*            size="xs"*/}
        {/*            outline*/}
        {/*        >*/}
        {/*            Add Date To*/}
        {/*            <PlusIcon className={styles.icon} />*/}
        {/*        </Button>*/}
        {/*    )}*/}
        {/*</div>*/}

        {/*<div className="d-flex align-items-end">*/}
        {/*    {values.activateDateFrom && (*/}
        {/*        <>*/}
        {/*            <Col xs={4}>*/}
        {/*                <FormGroup>*/}
        {/*                    <Label for="activateDateFrom" className={modalStyles.label}>*/}
        {/*                        From*/}
        {/*                    </Label>*/}
        {/*                    <DatePickerComponent*/}
        {/*                        filterTime={(date: Date): Date | boolean =>*/}
        {/*                            values?.activateDateTo*/}
        {/*                                ? filterByComparedDate(date, values?.activateDateTo)*/}
        {/*                                : date*/}
        {/*                        }*/}
        {/*                        value={values.activateDateFrom || formatLocalISODateTime(tomorrow)}*/}
        {/*                        onChange={(v) => setFieldValue('activateDateFrom', v)}*/}
        {/*                        error={touched.activateDateFrom && errors.activateDateFrom}*/}
        {/*                        maxDate={new Date(values.activateDateTo)}*/}
        {/*                        name="activateDateFrom"*/}
        {/*                        showTimeSelect*/}
        {/*                    />*/}
        {/*                </FormGroup>*/}
        {/*            </Col>*/}

        {/*            <Col xs={1}>*/}
        {/*                <Button*/}
        {/*                    onClick={() => setFieldValue('activateDateFrom', null)}*/}
        {/*                    className="px-3 mb-3"*/}
        {/*                    color="link"*/}
        {/*                    outline*/}
        {/*                >*/}
        {/*                    <DeleteIcon />*/}
        {/*                </Button>*/}
        {/*            </Col>*/}
        {/*        </>*/}
        {/*    )}*/}

        {/*    {values.activateDateTo && (*/}
        {/*        <>*/}
        {/*            <Col xs={4}>*/}
        {/*                <FormGroup>*/}
        {/*                    <Label for="activateDateTo" className={modalStyles.label}>*/}
        {/*                        To*/}
        {/*                    </Label>*/}
        {/*                    <DatePickerComponent*/}
        {/*                        filterTime={(date: Date): Date | boolean =>*/}
        {/*                            values.activateDateFrom*/}
        {/*                                ? filterByComparedDate(values.activateDateFrom, date)*/}
        {/*                                : date*/}
        {/*                        }*/}
        {/*                        value={values.activateDateTo || formatLocalISODateTime(new Date())}*/}
        {/*                        onChange={(v) => setFieldValue('activateDateTo', v)}*/}
        {/*                        error={touched.activateDateTo && errors.activateDateTo}*/}
        {/*                        minDate={new Date(values.activateDateFrom)}*/}
        {/*                        name="activateDateTo"*/}
        {/*                        showTimeSelect*/}
        {/*                    />*/}
        {/*                </FormGroup>*/}
        {/*            </Col>*/}

        {/*            <Col xs={1}>*/}
        {/*                <Button*/}
        {/*                    onClick={() => setFieldValue('activateDateTo', null)}*/}
        {/*                    className="px-3 mb-3"*/}
        {/*                    color="link"*/}
        {/*                    outline*/}
        {/*                >*/}
        {/*                    <DeleteIcon />*/}
        {/*                </Button>*/}
        {/*            </Col>*/}
        {/*        </>*/}
        {/*    )}*/}
        {/*</div>*/}
    </Row>
);
