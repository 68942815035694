import React from 'react';
import { Table } from 'reactstrap';

import { formatDateTime } from 'helpers/formatDateTime.helper';
import { Metric_metric_data } from 'graphql/queries/generatedTypes/Metric';
import { Preview_preview_data } from 'graphql/queries/generatedTypes/Preview';

import tableStyles from 'components/Table/Table.module.scss';

interface RetentionTableComponentProps {
    data: Metric_metric_data[] | Preview_preview_data[];
    retentionEvent: string;
    event: string;
}

const DATETIME_LENGTH = 10;

export const RetentionTableComponent: React.FC<RetentionTableComponentProps> = ({ data, retentionEvent, event }) => (
    <Table cssModule={tableStyles} hover responsive>
        <thead>
            <tr>
                <th />
                {data?.map(({ date }) => (
                    <th key={date}>{formatDateTime(date)?.slice(0, DATETIME_LENGTH)}</th>
                ))}
            </tr>
        </thead>

        <tbody>
            <tr>
                <td>First step - {event}</td>
                {data?.map((el) => (
                    <td key={el.date}>{el?.firstStep ?? '-'}</td>
                ))}
            </tr>
            <tr>
                <td>Second step - {retentionEvent}</td>
                {data?.map((el) => (
                    <td key={el.date}>{el?.secondStep ?? '-'}</td>
                ))}
            </tr>
            <tr>
                <td>Retention, %</td>
                {data?.map((el) => (
                    <td key={el.date}>{el?.metricValue !== null ? Math.floor(el.metricValue * 100) / 100 : '-'}</td>
                ))}
            </tr>
        </tbody>
    </Table>
);
