import React from 'react';
import { Input, InputProps, FormFeedback } from 'reactstrap';

export interface InputComponentProps extends InputProps {
    error?: string;
}

export const InputComponent: React.FC<InputComponentProps> = ({ type = 'text', error, children, ...props }) => (
    <>
        <Input invalid={!!error} type={type} {...props}>
            {children}
        </Input>
        {!!error && (
            <FormFeedback valid={false} className="mx-1">
                {error}
            </FormFeedback>
        )}
    </>
);
