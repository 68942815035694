import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { GreenRedMarkColor } from 'enums.types';
import { formatDateTime } from 'helpers/formatDateTime.helper';

import styles from './GreenRedMark.module.scss';

interface GreenRedMarkComponentProps {
    color: string;
    info?: string;
    id: string;
}

export const GreenRedMarkComponent: React.FC<GreenRedMarkComponentProps> = ({
    color = GreenRedMarkColor.RED,
    info,
    id,
}) => (
    <div>
        <div id={id} className={`${styles.label} ${styles[color?.toLocaleLowerCase()]}`} />
        {info && (
            <UncontrolledTooltip placement="right" target={id}>
                {formatDateTime(info)}
            </UncontrolledTooltip>
        )}
    </div>
);
