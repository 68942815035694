import React, { useCallback, useEffect, useState } from 'react';
import { FormikErrors } from 'formik';
import { Col, Row } from 'reactstrap';

import { deepCopyObject } from 'helpers/deepCopyObject.helper';
import { MaxLength } from 'validationSchema';
import { FakeInputComponent } from '../../../Input/FakeInput/FakeInput.component';
import { InputComponent } from '../../../Input/Input.component';
import { PopoverMenuComponent } from '../../../PopoverSelectInput/PopoverMenu.component';
import { conditionTypeOptions, setDefaultConditionValue } from './conditionTypeOptions.helper';
import { attributeTypeByConditionType, CONDITION_TYPES_WITH_TWO_INPUTS } from './attributeTypeByConditionType.helper';
import { DEFAULT_VALUES } from '../defaultValues.helper';
import { ConditionValuesComponent } from './ConditionValues/ConditionValues.component';
import { PopoverMenuItem } from 'components/PopoverSelectInput/PopoverMenu.component';
import {
    PostbackAttributeType,
    PostbackConditionType,
    AttributeConditionInput,
    PostbackConditionValueInput,
} from 'graphql/generatedGlobal.typings';

import styles from './ConditionItem.module.scss';

interface ConditionItemComponentProps {
    errors?: FormikErrors<AttributeConditionInput> | string | string[];
    onChange?: (value: AttributeConditionInput) => void;
    value: AttributeConditionInput;
    conditionIndex: number;
    isDisabled?: boolean;
}

const conditionTypeWithoutAttributeName = PostbackAttributeType.STRING;

const getDefaultValues = (type: PostbackConditionType): PostbackConditionValueInput[] =>
    CONDITION_TYPES_WITH_TWO_INPUTS.includes(type)
        ? [{ ...DEFAULT_VALUES }, { ...DEFAULT_VALUES }]
        : [{ ...DEFAULT_VALUES }];

export const ConditionItemComponent: React.FC<ConditionItemComponentProps> = ({
    isDisabled = false,
    onChange = () => {},
    conditionIndex,
    errors,
    value,
}) => {
    const [conditionOptions, setConditionOptions] = useState<PopoverMenuItem[]>([]);

    const handleConditionTypeChange = useCallback(
        (conditionType) =>
            onChange({
                ...value,
                conditionType,
                attributeType: attributeTypeByConditionType[conditionType as PostbackConditionType],
                conditionValues: getDefaultValues(conditionType as PostbackConditionType),
            }),
        [value]
    );

    const handleConditionValueChange = useCallback(
        (changedValue, changedIndex) => {
            const conditionValues = deepCopyObject(value?.conditionValues);

            conditionValues[changedIndex] = changedValue;
            onChange({
                ...value,
                conditionValues,
            });
        },
        [value]
    );

    useEffect(() => {
        // conditions to display conditionTypes by attributeName:
        const res = conditionTypeOptions.filter(({ value: conditionValue }) =>
            value?.attributeName ? true : conditionValue === conditionTypeWithoutAttributeName
        );
        setConditionOptions(res);

        // conditions to change attributeName by attributeType:
        if (!value?.attributeName && value.attributeType !== conditionTypeWithoutAttributeName) {
            handleConditionTypeChange(PostbackConditionType.STARTS_WITH_STRING);
        }
    }, [value?.attributeName]);

    useEffect(() => {
        // set default value for conditionTypes:
        if (value?.attributeType) {
            setDefaultConditionValue(value, onChange);
        }
    }, [value?.attributeType, value?.conditionType]);

    return (
        <fieldset disabled={isDisabled} className={styles.container}>
            <Row>
                <Col xs={3} className="px-1">
                    <InputComponent
                        error={errors && errors?.['attributeName']}
                        onChange={(e) =>
                            onChange({
                                ...value,
                                attributeName: e.target.value,
                            })
                        }
                        value={value.attributeName || ''}
                        maxLength={MaxLength.Postback}
                        placeholder="Attribute"
                        name="attributeName"
                    />
                </Col>

                <Col xs={3} className="px-1">
                    <PopoverMenuComponent
                        onSelect={handleConditionTypeChange}
                        id={conditionIndex.toString()}
                        options={conditionOptions}
                        disabled={isDisabled}
                    >
                        <FakeInputComponent
                            attributeType={value.attributeType}
                            value={value.conditionType}
                            isDisabled={isDisabled}
                            className="mt-2"
                            type="select"
                        />
                    </PopoverMenuComponent>
                </Col>

                {value?.conditionValues.map((conditionValue, conditionValueIndex) => (
                    <Col
                        xs={CONDITION_TYPES_WITH_TWO_INPUTS.includes(value?.conditionType) ? 3 : 6}
                        key={conditionValueIndex}
                        className="px-1"
                    >
                        <ConditionValuesComponent
                            onChange={(v) => handleConditionValueChange(v, conditionValueIndex)}
                            attributeType={value?.attributeType}
                            isDisabled={isDisabled}
                            value={conditionValue}
                            error={errors}
                        />
                    </Col>
                ))}
            </Row>
        </fieldset>
    );
};
