import { DocumentNode, gql } from '@apollo/client';

export const GET_ENVIRONMENTS: DocumentNode = gql`
    query Environments($projectId: String!) {
        environments(projectId: $projectId) {
            id
            name
            production
            projectId
            project {
                name
            }
        }
    }
`;
