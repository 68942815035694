import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { Paths } from 'enums.types';
import { lendingRoutes } from '../Header.helpers';
import { ReactComponent as LogoImg } from 'assets/img/logo.svg';
import { ReactComponent as CloseIcon } from 'assets/img/x-lg.svg';
import { ReactComponent as BurgerImg } from 'assets/img/burger-menu.svg';

import styles from './MobileMenuModal.module.scss';

interface MobileMenuModalProps {
    isOpen: boolean;
    setIsOpen: (boolean) => void;
}

export const MobileMenuModalComponent: React.FC<MobileMenuModalProps> = ({ isOpen, setIsOpen }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <Button onClick={() => setIsOpen(true)} className={styles.burgerBtn} color="link">
                <BurgerImg />
            </Button>

            <Modal
                size="xxl"
                fade={true}
                isOpen={isOpen}
                backdrop="static"
                cssModule={styles}
                toggle={(): void => setIsOpen(false)}
            >
                <ModalHeader cssModule={styles}>
                    <div className={styles.logoContainer}>
                        <LogoImg className={styles.logoImg} />
                    </div>
                    <CloseIcon className={styles.closeButton} onClick={(): void => setIsOpen(false)} />
                </ModalHeader>

                <ModalBody cssModule={styles}>
                    <ul className={styles.menuContainer}>
                        {lendingRoutes.map(({ path, menuItem }) => (
                            <li className={styles.menuItem} key={menuItem}>
                                <a href={path} className={styles.menuLink}>
                                    {menuItem}
                                </a>
                            </li>
                        ))}
                    </ul>

                    <div className={styles.registrationBtnContainer}>
                        <Button
                            onClick={() => {
                                navigate(location?.pathname === Paths.Registration ? Paths.Login : Paths.Registration);
                                setIsOpen(false);
                            }}
                            className={location?.pathname === Paths.Login ? styles.registrationBtn : styles.loginBtn}
                            color={location?.pathname === Paths.Login ? 'primary' : 'secondary'}
                            outline={location?.pathname === Paths.Registration}
                        >
                            {location?.pathname === Paths.Login ? 'Sign up' : 'Login'}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
