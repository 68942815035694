import { DocumentNode, gql } from '@apollo/client';

export const CREATE_API_KEY: DocumentNode = gql`
    mutation CreateApiKey($input: CreateApiKeyInput!) {
        createApiKey(input: $input) {
            id
            name
            key
            type
            organizationId
            projectId
            createdAt
            createdBy
        }
    }
`;
