import * as yup from 'yup';
import { ApiKeyType } from 'graphql/generatedGlobal.typings';

export const API_KEY_NAME_LENGTH = 100;

export const createApiKeyModalValidation = yup.object({
    name: yup.string().required('Required'),
    projectId: yup.string().required('Required'),
    environmentId: yup
        .string()
        .nullable()
        .when('type', {
            is: (type) => type !== ApiKeyType.ADMIN,
            then: yup.string().required('Required'),
        }),
});
