import React from 'react';

import { Paths } from 'enums.types';
import {
    AsyncRegistration,
    AsyncUserSettings,
    AsyncExperiments,
    AsyncPostbacks,
    AsyncAnalytics,
    AsyncPostback,
    AsyncRecovery,
    AsyncDataHub,
    AsyncMetric,
    AsyncInvite,
    AsyncLogin,
} from '../containers';
import { RoutesTypes } from './routes.types';
import { ReactComponent as ExperimentIcon } from 'assets/img/flask.svg';
import { ReactComponent as DataHubIcon } from 'assets/img/hub.svg';
import { ReactComponent as WebhooksIcon } from 'assets/img/webhooks.svg';
import { ReactComponent as MetricsIcon } from 'assets/img/metrics.svg';

import styles from 'components/Header/Header.module.scss';

export const routes: RoutesTypes[] = [
    {
        path: Paths.Login,
        element: <AsyncLogin />,
    },
    {
        path: Paths.Registration,
        element: <AsyncRegistration />,
    },
    {
        path: Paths.Invite,
        element: <AsyncInvite />,
        nestedPath: ':token',
    },
    {
        path: Paths.Recovery,
        element: <AsyncRecovery />,
        nestedPath: ':token',
    },
    {
        path: Paths.UserSettings,
        element: <AsyncUserSettings />,
    },
    {
        path: Paths.Experiments,
        element: <AsyncExperiments />,
        menuItem: 'Experiments',
        icon: <ExperimentIcon className={styles.icon} />,
    },
    {
        path: Paths.DataHub,
        element: <AsyncDataHub />,
        menuItem: 'Data Hub',
        icon: <DataHubIcon className={styles.icon} />,
    },
    {
        path: Paths.Webhooks,
        element: <AsyncPostbacks />,
        menuItem: 'Webhooks',
        icon: <WebhooksIcon className={styles.icon} />,
    },
    {
        path: `${Paths.Webhooks}/:webhookId`,
        element: <AsyncPostback />,
    },
    {
        path: Paths.Analytics,
        element: <AsyncAnalytics />,
        menuItem: 'Analytics',
        icon: <MetricsIcon className={styles.icon} />,
    },
    {
        path: `${Paths.Metric}/:metricId`,
        element: <AsyncMetric />,
    },
];
