import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

import { useOrganizationContext } from 'context/Organization.context';
import { useEnvironmentContext } from 'context/Environments.context';
import { DataHubPageTab, Paths } from 'enums.types';
import { deepCopyObject } from 'helpers/deepCopyObject.helper';
import { ErrorOrNotFoundComponent } from '../ErrorOrNotFound/ErrorOrNotFound.component';
import { PageSpinnerComponent } from '../PageSpinner/PageSpinner.component';
import { ExperimentResultTableComponent } from './ExperimentResultTable/ExperimentResultTable.component';
import { ExperimentResultFormComponent } from './ExperimentResultForm/ExperimentResultForm.component';
import { ExperimentsWithDefinition_experimentsWithDefinition } from 'graphql/queries/generatedTypes/ExperimentsWithDefinition';
import {
    ExperimentResultFilterCategory,
    ExperimentResultFilterInput,
    ExperimentResultSearchInput,
    ExperimentResultSortInput,
    ExperimentResultSortOrder,
} from 'graphql/generatedGlobal.typings';
import {
    SearchExperimentResults,
    SearchExperimentResultsVariables,
} from 'graphql/queries/generatedTypes/SearchExperimentResults';
import { GET_SEARCH_EXPERIMENT_RESULTS } from 'graphql/queries/getSearchExperimentResults.query';

import styles from './ExperimentResult.module.scss';

interface ExperimentResultComponentProps {
    experiment?: ExperimentsWithDefinition_experimentsWithDefinition;
}

const validationSchema = yup.object({
    filters: yup.array().of(
        yup.object().shape({
            category: yup.mixed().required('Required'),
            value: yup.mixed().required('Required'),
        })
    ),
});

const DEFAULT_ITEMS_PER_PAGE = 50;
const quantityLimitItems = [DEFAULT_ITEMS_PER_PAGE, 100, 200];

export const ExperimentResultComponent: React.FC<ExperimentResultComponentProps> = ({ experiment }) => {
    const { currentProject } = useOrganizationContext();
    const { currentEnvironment } = useEnvironmentContext();

    const location = useLocation();

    const [queryLimit, setQueryLimit] = useState<number>(DEFAULT_ITEMS_PER_PAGE);
    const [querySort, setQuerySort] = useState<ExperimentResultSortInput[]>([]);
    const [isShowTable, setIsShowTable] = useState<boolean>(false);
    const [isValidateOnChange, setIsValidateOnChange] = useState<boolean>(false);
    const [updateColumnsTrigger, setUpdateColumnsTrigger] = useState<boolean>(false);

    const [getSearchExperimentResults, { data, loading, error, fetchMore }] = useLazyQuery<
        SearchExperimentResults,
        SearchExperimentResultsVariables
    >(GET_SEARCH_EXPERIMENT_RESULTS);

    const prepareFiltersForSending = useCallback(
        (val: ExperimentResultSearchInput): ExperimentResultFilterInput[] | null => {
            const { filters } = deepCopyObject(val);

            // add filter for query by experiment and domainType
            if (location?.pathname !== `${Paths.DataHub}/${DataHubPageTab.ExperimentResults}` && experiment?.id) {
                const experimentFilters = [
                    {
                        category: ExperimentResultFilterCategory.EXPERIMENT_NAME,
                        value: experiment?.name,
                    },
                ];
                filters.push(...experimentFilters);
            } else if (
                location?.pathname !== `${Paths.DataHub}/${DataHubPageTab.ExperimentResults}` &&
                !experiment?.id
            ) {
                // Wait fetch experiment data:
                return null;
            }

            return filters;
        },
        [experiment?.id]
    );

    const onSubmit = useCallback(
        async (submittingValues: ExperimentResultSearchInput) => {
            const filters = await prepareFiltersForSending(submittingValues);

            setIsShowTable(true);

            if (filters) {
                getSearchExperimentResults({
                    variables: {
                        input: {
                            ...input,
                            environmentId: submittingValues.environmentId,
                            filters,
                        },
                    },
                    fetchPolicy: 'network-only',
                });
            }
        },
        [experiment, currentEnvironment, queryLimit, querySort, isShowTable]
    );

    const { handleSubmit, setFieldValue, resetForm, values, errors, isValid, isSubmitting } = useFormik({
        initialValues: {
            environmentId: currentEnvironment?.id,
            filters: [],
        },
        onSubmit,
        validationSchema,
        validateOnChange: isValidateOnChange,
        validateOnBlur: isValidateOnChange,
    });

    useEffect(() => {
        if (currentEnvironment?.id) {
            setFieldValue('environmentId', currentEnvironment?.id);
        }
    }, [currentEnvironment?.id]);

    const input = useMemo(
        () => ({
            environmentId: values.environmentId,
            sort: querySort,
            limit: queryLimit,
            offset: 0,
            filters: [],
        }),
        [values, queryLimit, querySort, currentEnvironment?.id]
    );

    const changeSortOrder = useCallback(
        async (sortField) => {
            const filters = await prepareFiltersForSending(values);
            const sort = [
                {
                    field: sortField,
                    order:
                        querySort?.[0]?.field && querySort?.[0]?.order !== ExperimentResultSortOrder.ASC
                            ? ExperimentResultSortOrder.ASC
                            : ExperimentResultSortOrder.DESC,
                },
            ];

            await setQuerySort([...sort]);

            getSearchExperimentResults({
                variables: {
                    input: {
                        ...input,
                        filters: filters?.filter(({ value }) => value),
                        sort,
                    },
                },
            });
        },
        [experiment, currentEnvironment, values, queryLimit, querySort]
    );

    const onFetchMoreResults = useCallback(async () => {
        const filters = await prepareFiltersForSending(values);

        fetchMore({
            variables: {
                input: {
                    ...input,
                    filters: filters?.filter(({ value }) => value),
                    offset: data?.searchExperimentResults?.result.length,
                },
            },
            updateQuery: (previous: SearchExperimentResults, { fetchMoreResult }) => {
                if (!fetchMoreResult?.searchExperimentResults?.result) return previous;

                const result = [
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    ...previous?.searchExperimentResults?.result,
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    ...fetchMoreResult?.searchExperimentResults?.result,
                ];

                return {
                    searchExperimentResults: {
                        ...fetchMoreResult.searchExperimentResults,
                        result,
                    },
                };
            },
        });
    }, [values, data, experiment, currentEnvironment, queryLimit]);

    const renderLimitButton = useCallback(
        (val: number) => (
            <Button
                className={val === queryLimit ? styles.activeBtn : ''}
                onClick={async () => {
                    const filters = await prepareFiltersForSending(values);

                    setQueryLimit(val);
                    getSearchExperimentResults({
                        variables: {
                            input: {
                                ...input,
                                limit: val,
                                filters: filters?.filter(({ value }) => value),
                            },
                        },
                        fetchPolicy: 'network-only',
                    });
                }}
                type="button"
                color="link"
            >
                {val}
            </Button>
        ),
        [data, values, queryLimit, currentEnvironment]
    );

    useEffect(() => {
        if (currentProject?.id && currentEnvironment?.id) {
            const filters = prepareFiltersForSending(values);

            setIsShowTable(true);

            if (filters) {
                getSearchExperimentResults({
                    variables: {
                        input: {
                            filters,
                            environmentId: currentEnvironment?.id,
                            limit: queryLimit,
                            sort: querySort,
                            offset: 0,
                        },
                    },
                    fetchPolicy: 'network-only',
                });
            }
        }
    }, [currentProject?.id, currentEnvironment?.id, experiment?.id]);

    useEffect(() => {
        // Unmount table by change experiment:
        return () => {
            if (experiment?.id) {
                setIsShowTable(false);
                resetForm();
                setQueryLimit(DEFAULT_ITEMS_PER_PAGE);
                setFieldValue('filters', []);
            }
        };
    }, [experiment?.id]);

    useEffect(() => {
        // Add ability to remove error after submit before next submit action
        if (errors?.filters?.length && isSubmitting) {
            setIsValidateOnChange(true);
        }
    }, [errors, isSubmitting]);

    return (
        <div className="p-4">
            <ExperimentResultFormComponent
                downloadFilters={prepareFiltersForSending(values)}
                setUpdateColumnsTrigger={setUpdateColumnsTrigger}
                setIsValidateOnChange={setIsValidateOnChange}
                updateColumnsTrigger={updateColumnsTrigger}
                isExperimentPage={!!experiment}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                loading={loading}
                isValid={isValid}
                sort={querySort}
                errors={errors}
                values={values}
            />

            <div className={styles.tableLimitContainer}>
                <p className="m-0 text-muted">
                    {`Found: ${data?.searchExperimentResults?.pageInfo?.totalCount || 0} records`}
                </p>
                {isShowTable && data?.searchExperimentResults?.result && (
                    <div className={styles.limitContainer}>
                        {quantityLimitItems.map((el) => (
                            <React.Fragment key={el}>{renderLimitButton(el)}</React.Fragment>
                        ))}
                    </div>
                )}
            </div>

            <ExperimentResultTableComponent
                data={data?.searchExperimentResults?.result}
                updateColumnsTrigger={updateColumnsTrigger}
                changeSortOrder={changeSortOrder}
                isExperimentPage={!!experiment}
                currentSort={querySort?.[0]}
                isShowTable={isShowTable}
            />
            {isShowTable &&
                data?.searchExperimentResults?.result &&
                data?.searchExperimentResults?.pageInfo &&
                data.searchExperimentResults?.pageInfo?.totalCount > data.searchExperimentResults.result.length && (
                    <Button onClick={onFetchMoreResults} color="secondary" className="my-3">
                        Show more
                    </Button>
                )}

            {loading && <PageSpinnerComponent />}
            {isShowTable && (error || data?.searchExperimentResults?.result?.length === 0) && (
                <ErrorOrNotFoundComponent error={error} />
            )}
            {!isShowTable && <p className="px-2">Specify query criteria</p>}
        </div>
    );
};
