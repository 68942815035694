import { gql } from '@apollo/client';

export const UserQuery = gql`
    query User {
        user @rest(type: "Get", path: "/rio-client-gateway/user", method: "GET") {
            id
            email
            organizationId
            timezone
        }
    }
`;
