const dictionary: Record<string, string> = {
    'experiment.not.in.archived.status': 'Experiment not in archived status',
    'default.rule.variation.not.exist': "Default rule with this name doesn't exist",
    'default.variation.not.exist': "Default variation with this name doesn't exist",
    'password.not.match': 'Password is not match',
    'password.rule.not.respected': 'Password is not correct',
    'phone.format': 'Phone format is not valid',

    'experiment.name.unique': 'This experiment name is already being used',
    'environment.name.not.unique': 'This environment name is already being used',
    'domainType.name.not.unique': 'This domain type name is already being used',
    'email.not.unique': 'This email address is already being used',
    'phone.not.unique': 'This phone number is already being used',
    'project.name.not.unique': 'This project name is already being used',
    'aggregate.domain.not.unique': 'This domain name is already being used',
    'field.name.not.unique': 'This field name is already being used',
    'postback.name.not.unique': 'This postback name is already being used',
    'metric.name.not.unique': 'This metric name is already being used',

    'targeting.rule.with.attributeType.experiment.exist':
        'The selected definition cannot be copied because it has an experiment in the targeting rules',
    'targeting.rule.condition.experiment.id.circular.dependency':
        "The current experiment's targeting rule has a circular relationship with another experiment",
    'targeting.rule.condition.number.value.order': 'First value is more or equal than the second',
    'targeting.rule.condition.number.value.wrong.order':
        "The current experiment's targeting rule has the wrong order of values",
    'targeting.rule.condition.dateTime.value.wrong.order':
        "The current experiment's targeting rule has the wrong date order",
    'targeting.rule.attribute.name.is.empty': 'Attribute name is empty',

    'user.emails.invitation.exist': 'This invitation is already being used',
    'user.emails.is.registered': 'Email already is registered',
    'user.emails.not.valid': 'Email is not valid',
    'user.password.not.valid':
        'Must contain 8 - 64 characters, one Latin uppercase, one Latin lowercase, one number and one special case character',

    'erasable.with.array.type': 'Incorrect value for Erasable is not supported',
    'invalid.upsert.type': 'Upsert type is not valid',
    'field.type.not.number': 'Incorrect value for Upsert type is not supported',
    'object.array.not.supported': 'Incorrect value for Type is not supported',
    'environment.not.found': "Environment with this name doesn't exist",
    'domain.not.found': "Domain type with this name doesn't exist",
    'root.domain.not.found': "Domain type in root with this name doesn't exist",

    DATE_FROM: 'Date From',
    DATE_TO: 'Date To',
    DOMAIN_ID: 'Domain ID',
    DOMAIN_NAME: 'Domain name',
    ENVIRONMENT_ID: 'Environment',
    EXPERIMENT_NAME: 'Experiment',
    LOCATION_TAG: 'Location tag',
    VARIATION: 'Variation',

    CREATE: 'Experiment created',
    ARCHIVE: 'Experiment archived',
    RESTORE: 'Experiment restored',
    DEF_CREATE: 'Definition created',
    DEF_UPDATE: 'Definition updated',
    DEF_KILL: 'Definition killed',
    DEF_ACTIVATE: 'Definition activated',

    GIGANT: 'Giant',
    BIG: 'Big',
    MEDIUM: 'Medium',
    SMALL: 'Small',
    MICRO: 'Micro',

    API_KEY: 'API Key',
    EXPERIMENT: 'Experiment',

    SERVER_SIDE: 'Server Side',
    CLIENT_SIDE: 'Client Side',
    ADMIN: 'Admin',

    DOMAIN_TYPE: 'Domain type',
    ENVIRONMENT: 'Environment',
    HANDLED_VARIATIONS: 'Handled variations',
    TIMESTAMP: 'Timestamp',
    SDK: 'SDK',
    SDK_VERSION: 'SDK Version',
    MACHINE_IP: 'Machine IP',
    ATTRIBUTES: 'Attributes',
    VERSION: 'Version',

    EVENT_NAME: 'Event',
    NAME: 'Name',
    ROOT_ID: 'Root ID',
    ROOT_TYPE: 'Root type',

    ARRAY: 'Array',
    FIRST_ONLY: 'First only',
    INCREMENT: 'Increment',
    OVERWRITE: 'Overwrite',
    SET: 'Set',

    BOOLEAN: 'Boolean',
    DATE: 'Date',
    DATE_TIME: 'Date time',
    DATETIME: 'DateTime',
    INT: 'Integer',
    OBJECT: 'Object',
    STRING: 'String',
    DOUBLE: 'Double',
    NUMBER: 'Number',

    METHOD: 'Method',
    STATUS: 'Status',

    DISABLED: 'Disabled',
    ENABLED: 'Enabled',

    AGGREGATE: 'Aggregate',
    AGGREGATE_PER_DOMAIN: 'Aggregate per domain',
    COUNT: 'Count',
    COUNT_PER_DOMAIN: 'Count per domain',
    RETENTION: 'Retention',

    HAS_DONE_EVENT: 'Has done event',

    AVERAGE: 'Average',
    MAX: 'Max',
    MIN: 'Min',
    SUM: 'Sum',

    CONTAINS: 'Contains',
    ENDS_WITH: 'Ends with',
    EQUAL: 'Equal',
    GREATER_THAN_OR_EQUAL: 'Greater than or equal',
    LESS_THAN_OR_EQUAL: 'Less than or equal',
    NOT_CONTAINS: 'Not contains',
    NOT_EQUAL: 'Not equal',
    STARTS_WITH: 'Starts with',

    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',

    YESTERDAY: 'Yesterday',
    TODAY: 'Today',
    THIS_WEEK: 'This week',
    THIS_MONTH: 'This month',
    LAST_7_DAYS: 'Last 7 days',
    LAST_14_DAYS: 'Last 14 days',
    LAST_30_DAYS: 'Last 30 days',
    LAST_48_HOURS: 'Last 48 hours',
};

export const getDictionary = (key: string): string => dictionary[key] || key;
