import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ReactComponent as CloseIcon } from 'assets/img/x-lg.svg';

import modalStyles from 'components/Modal/Modal.module.scss';

interface AttributesModalProps {
    data: JSON | string;
}

export const AttributesModalComponent: React.FC<AttributesModalProps> = ({ data }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <Button onClick={() => setIsOpen(true)} color="link" className="p-0">
                <span>Show attributes</span>
            </Button>

            <Modal
                centered
                size="xl"
                isOpen={isOpen}
                backdrop="static"
                cssModule={modalStyles}
                toggle={(): void => setIsOpen(false)}
            >
                <ModalHeader cssModule={modalStyles}>
                    Attributes
                    <CloseIcon className={modalStyles.closeButton} onClick={(): void => setIsOpen(false)} />
                </ModalHeader>

                <ModalBody cssModule={modalStyles} className={modalStyles.modalBodyWithoutFooter}>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </ModalBody>
            </Modal>
        </>
    );
};
