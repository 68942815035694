import { PopoverMenuItem } from 'components/PopoverSelectInput/PopoverMenu.component';
import { formatLocalISODateTime } from 'helpers/formatDateTime.helper';
import { PostbackConditionType, PostbackAttributeType, AttributeConditionInput } from 'graphql/generatedGlobal.typings';

export const conditionTypeOptions: PopoverMenuItem[] = [
    {
        label: 'string',
        value: PostbackAttributeType.STRING,
        children: [
            {
                label: 'starts with',
                value: PostbackConditionType.STARTS_WITH_STRING,
            },
            {
                label: 'is in list',
                value: PostbackConditionType.IN_LIST_STRING,
            },
            {
                label: 'is not in list',
                value: PostbackConditionType.NOT_IN_LIST_STRING,
            },
            {
                label: 'does not start with',
                value: PostbackConditionType.NOT_STARTS_WITH_STRING,
            },
            {
                label: 'ends with',
                value: PostbackConditionType.ENDS_WITH_STRING,
            },
            {
                label: 'does not end with',
                value: PostbackConditionType.NOT_ENDS_WITH_STRING,
            },
            {
                label: 'contains',
                value: PostbackConditionType.CONTAINS_STRING,
            },
            {
                label: 'does not contain',
                value: PostbackConditionType.NOT_CONTAINS_STRING,
            },
            {
                label: 'matches',
                value: PostbackConditionType.MATCHES_STRING,
            },
            {
                label: 'does not match',
                value: PostbackConditionType.NOT_MATCHES_STRING,
            },
        ],
    },
    {
        label: 'set',
        value: PostbackAttributeType.SET,
        children: [
            {
                label: 'is equal to',
                value: PostbackConditionType.EQUAL_SET,
            },
            {
                label: 'is not equal to',
                value: PostbackConditionType.NOT_EQUAL_SET,
            },
            {
                label: 'has any of',
                value: PostbackConditionType.ANY_OF_SET,
            },
            {
                label: 'does not have any of',
                value: PostbackConditionType.NOT_ANY_OF_SET,
            },
            {
                label: 'has all of',
                value: PostbackConditionType.ALL_OF_SET,
            },
            {
                label: 'does not have all of',
                value: PostbackConditionType.NOT_ALL_OF_SET,
            },
            {
                label: 'is part of',
                value: PostbackConditionType.PART_OF_SET,
            },
            {
                label: 'is not part of',
                value: PostbackConditionType.NOT_PART_OF_SET,
            },
        ],
    },
    {
        label: 'date',
        value: [PostbackAttributeType.DATE, PostbackAttributeType.DATETIME],
        children: [
            {
                label: 'is on',
                value: PostbackConditionType.ON_DATE,
            },
            {
                label: 'is not on',
                value: PostbackConditionType.NOT_ON_DATE,
            },
            {
                label: 'is on or before',
                value: PostbackConditionType.ON_OR_BEFORE_DATE_TIME,
            },
            {
                label: 'is on or after',
                value: PostbackConditionType.ON_OR_AFTER_DATE_TIME,
            },
            {
                label: 'is between',
                value: PostbackConditionType.BETWEEN_DATE_TIME,
            },
            {
                label: 'is not between',
                value: PostbackConditionType.NOT_BETWEEN_DATE_TIME,
            },
        ],
    },
    {
        label: 'number',
        value: PostbackAttributeType.NUMBER,
        children: [
            {
                label: 'is =',
                value: PostbackConditionType.EQUAL_NUMBER,
            },
            {
                label: 'is not =',
                value: PostbackConditionType.NOT_EQUAL_NUMBER,
            },
            {
                label: 'is <=',
                value: PostbackConditionType.LESS_THAN_OR_EQUAL_NUMBER,
            },
            {
                label: 'is >=',
                value: PostbackConditionType.GREATER_THAN_OR_EQUAL_NUMBER,
            },
            {
                label: 'is between',
                value: PostbackConditionType.BETWEEN_NUMBER,
            },
            {
                label: 'is not between',
                value: PostbackConditionType.NOT_BETWEEN_NUMBER,
            },
        ],
    },
    {
        label: 'boolean',
        value: PostbackAttributeType.BOOLEAN,
        children: [
            {
                label: 'is',
                value: PostbackConditionType.BOOLEAN,
            },
        ],
    },
];

export const setDefaultConditionValue = (
    value: AttributeConditionInput,
    onChange: (value: AttributeConditionInput) => void
): void => {
    // set default value for conditionTypes:
    switch (value?.attributeType) {
        case PostbackAttributeType.BOOLEAN: {
            const newConditionValues = [...value.conditionValues];
            const conditionValues = newConditionValues?.map((el) => {
                if (el.booleanValue === null) {
                    return {
                        ...el,
                        booleanValue: false,
                    };
                }
                return el;
            });

            onChange({ ...value, conditionValues });
            break;
        }
        case PostbackAttributeType.DATE:
        case PostbackAttributeType.DATETIME: {
            const newConditionValues = [...value.conditionValues];
            const isDateAttributeType = value.attributeType === PostbackAttributeType.DATE;
            const conditionValues = newConditionValues?.map((el, i) => {
                const propName = isDateAttributeType ? 'dateValue' : 'dateTimeValue';
                const tomorrow = new Date();

                tomorrow.setDate(tomorrow.getDate() + 1);
                const newDateTimeValue = formatLocalISODateTime(i === 0 ? new Date() : tomorrow);

                if (!el[propName]) {
                    return {
                        ...el,
                        [propName]: isDateAttributeType
                            ? formatLocalISODateTime(new Date())?.substring(0, 10)
                            : newDateTimeValue,
                    };
                }
                return el;
            });

            onChange({ ...value, conditionValues });
            break;
        }
    }
};
