import React, { ChangeEventHandler, FocusEvent, useCallback } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';

import { InputComponent } from '../../Input/Input.component';
import { deepCopyObject } from 'helpers/deepCopyObject.helper';
import { HeaderInput, HttpMethod } from 'graphql/generatedGlobal.typings';
import { PostbackInput } from '../PostbackModal.types';
import { ReactComponent as PlusIcon } from 'assets/img/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/x-red.svg';

import modalStyles from 'components/Modal/Modal.module.scss';
import styles from '../PostbackModal.module.scss';

interface RequestComponentProps {
    values: PostbackInput;
    touched: FormikTouched<PostbackInput>;
    errors: FormikErrors<PostbackInput>;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: (e: FocusEvent) => void;
    setFieldValue: (string, unknown) => void;
    setIsValidateOnChange: (boolean) => void;
}

const DEFAULT_HEADERS_ITEM: HeaderInput = {
    value: '',
    key: '',
};

export const RequestComponent: React.FC<RequestComponentProps> = ({
    setIsValidateOnChange,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
}) => {
    const addHeadersItemHandler = useCallback(() => {
        const { headers } = deepCopyObject(values);

        setFieldValue('headers', [...headers, DEFAULT_HEADERS_ITEM]);
    }, [values]);

    const deleteHeadersItemHandler = useCallback(
        (deletedIndex: number) => {
            const filteredHeaders = values.headers?.filter((el, i) => i !== deletedIndex);

            setFieldValue('headers', filteredHeaders);

            if (!filteredHeaders.length) {
                setIsValidateOnChange(false);
            }
        },
        [values, setIsValidateOnChange]
    );

    return (
        <>
            <Row>
                <b className="mt-1 mb-0 text-decoration-underline">HTTP Request:</b>
                <Col xs={4}>
                    <Label for="method" className={modalStyles.label}>
                        Method *
                    </Label>
                    <InputComponent
                        error={touched.method && errors.method}
                        onChange={handleChange}
                        value={values.method}
                        onBlur={handleBlur}
                        name="method"
                        type="select"
                    >
                        {Object.keys(HttpMethod)?.map((el) => (
                            <option key={el} value={el}>
                                {el}
                            </option>
                        ))}
                    </InputComponent>
                </Col>

                <Col xs={8}>
                    <Label for="url" className={modalStyles.label}>
                        URL *
                    </Label>
                    <InputComponent
                        error={touched.url && errors.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.url}
                        name="url"
                    />
                </Col>
            </Row>

            <div className={`${modalStyles.label} d-flex justify-content-start align-items-center mt-3 mb-3`}>
                <p className={`${modalStyles.label} my-1`}>Headers:</p>
                <Button onClick={addHeadersItemHandler} className="mx-3 border-0" color="primary" size="xs" outline>
                    Add
                    <PlusIcon className={styles.icon} />
                </Button>
            </div>

            {!!values?.headers?.length &&
                values.headers.map(({ key, value }, i) => (
                    <Row key={i} className="mb-2">
                        <Col xs={5}>
                            <InputComponent
                                error={touched.headers?.[i]?.['key'] && errors.headers?.[i]?.['key']}
                                name={`headers.[${i}].key`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Key"
                                value={key}
                            />
                        </Col>

                        <Col xs={6}>
                            <InputComponent
                                error={touched.headers?.[i]?.['value'] && errors.headers?.[i]?.['value']}
                                name={`headers.[${i}].value`}
                                onChange={handleChange}
                                placeholder="Value"
                                onBlur={handleBlur}
                                value={value}
                            />
                        </Col>

                        <Col xs={1}>
                            <Button onClick={() => deleteHeadersItemHandler(i)} className="px-0" color="link" outline>
                                <DeleteIcon />
                            </Button>
                        </Col>
                    </Row>
                ))}

            <FormGroup className="mt-1">
                <Label for="body" className={modalStyles.label}>
                    Body
                </Label>
                <InputComponent
                    error={touched.body && errors.body}
                    className={styles.code}
                    rows={values?.body ? 6 : 2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.body}
                    type="textarea"
                    name="body"
                />
            </FormGroup>
        </>
    );
};
