import React from 'react';

import { Paths } from 'enums.types';
import {
    AsyncProjects,
    AsyncProject,
    AsyncApiKeys,
    AsyncEnvironments,
    AsyncDomainTypes,
    AsyncUsers,
} from './SettingsTabs';
import { RoutesTypes } from '../../routes/routes.types';
import { ReactComponent as ProjectsIcon } from 'assets/img/boxes.svg';
import { ReactComponent as KeyIcon } from 'assets/img/key.svg';
import { ReactComponent as BuildingIcon } from 'assets/img/building.svg';
import { ReactComponent as GlobeIcon } from 'assets/img/globe.svg';
import { ReactComponent as UserIcon } from 'assets/img/users.svg';

import styles from './SettingsAside/SettingsAside.module.scss';

export const settingsPageRoutes: RoutesTypes[] = [
    {
        path: Paths.Projects,
        element: <AsyncProjects />,
        menuItem: 'Projects',
        icon: <ProjectsIcon className={styles.navIcon} />,
    },
    {
        path: `${Paths.Projects}/*`,
        element: <AsyncProject />,
        menuItem: 'Project',
    },
    {
        path: Paths.Keys,
        element: <AsyncApiKeys />,
        menuItem: 'API keys',
        icon: <KeyIcon className={styles.navIcon} />,
    },
    {
        path: Paths.Environments,
        element: <AsyncEnvironments />,
        menuItem: 'Environments',
        icon: <GlobeIcon className={styles.navIcon} />,
    },
    {
        path: Paths.DomainTypes,
        element: <AsyncDomainTypes />,
        menuItem: 'Domain types',
        icon: <BuildingIcon className={styles.navIcon} />,
    },
    {
        path: Paths.Users,
        element: <AsyncUsers />,
        menuItem: 'Users',
        icon: <UserIcon className={styles.navIcon} />,
    },
];
