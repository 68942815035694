import React, { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Button } from 'reactstrap';

import { DEFAULT_CONDITION } from './defaultValues.helper';
import { deepCopyObject } from 'helpers/deepCopyObject.helper';
import { ConditionItemComponent } from './ConditionItem/ConditionItem.component';
import { AttributeConditionInput } from 'graphql/generatedGlobal.typings';
import { ReactComponent as PlusIcon } from 'assets/img/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/minus.svg';

import styles from './Condition.module.scss';

interface ConditionComponentProps {
    errors: FormikErrors<AttributeConditionInput[]> | string | string[];
    onChange: (conditions: AttributeConditionInput[]) => void;
    conditions: AttributeConditionInput[];
}

export const ConditionComponent: React.FC<ConditionComponentProps> = ({ conditions, errors, onChange }) => {
    const handleChange = useCallback(
        (changedValue: AttributeConditionInput, changedIndex: number) => {
            const newConditions = deepCopyObject(conditions);

            newConditions[changedIndex] = changedValue;
            onChange(newConditions);
        },
        [conditions]
    );

    const handleConditionDelete = useCallback(
        (conditionIndex: number) => {
            const filteredConditions = conditions?.filter((el, i) => i !== conditionIndex);

            onChange(deepCopyObject(filteredConditions));
        },
        [conditions]
    );

    return (
        <div className={styles.conditionContainer}>
            {conditions?.map((condition: AttributeConditionInput, conditionIndex: number) => (
                <div className={styles.conditionItem} key={conditionIndex}>
                    <div className={styles.conditionTextContainer}>
                        <p className={styles.conditionText}>{conditionIndex === 0 ? 'IF' : 'AND'}</p>
                    </div>

                    <ConditionItemComponent
                        onChange={(v) => handleChange(v, conditionIndex)}
                        errors={errors?.[conditionIndex]}
                        conditionIndex={conditionIndex}
                        value={condition}
                    />

                    {conditions?.length > 1 && (
                        <Button
                            onClick={() => handleConditionDelete(conditionIndex)}
                            className="p-0 mt-3 mx-1"
                            color="link"
                            outline
                        >
                            <DeleteIcon className={styles.minusIcon} />
                        </Button>
                    )}
                </div>
            ))}

            <div className="d-flex justify-content-start align-items-center my-2">
                <Button
                    onClick={() => onChange([...conditions, ...deepCopyObject([DEFAULT_CONDITION])])}
                    className="my-3"
                    color="primary"
                    outline
                >
                    Add Condition
                    <PlusIcon className={styles.plusIcon} />
                </Button>

                {!!conditions.length && (
                    <Button
                        onClick={() => onChange([])}
                        className={styles.removeConditionBtn}
                        color="danger"
                        size="xs"
                        outline
                    >
                        Remove Conditions
                    </Button>
                )}
            </div>
        </div>
    );
};
