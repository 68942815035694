export enum Paths {
    Login = '/login',
    Recovery = '/reset-password',
    Invite = '/complete-invite',
    Registration = '/registration',
    Experiments = '/experiments',
    DataHub = '/data-hub',
    Webhooks = '/webhooks',
    Analytics = '/analytics',
    Metric = '/metric',
    Settings = '/settings',
    UserSettings = '/user-settings',
    Projects = 'projects',
    Keys = 'keys',
    Environments = 'environments',
    DomainTypes = 'domain-types',
    Users = 'users',
}

export const enum EnvironmentType {
    PRE_PRODUCTION = 'Pre-production',
    PRODUCTION = 'Production',
}

export const enum ExperimentTab {
    Definition = 'definition',
    Results = 'results',
    ChangeLog = 'change-log',
    CodeAndTest = 'code-test',
    Usage = 'usage',
    Analytics = 'analytics',
}

export const enum ExperimentTabName {
    Definition = 'Definition',
    Results = 'Results',
    ChangeLog = 'Change log',
    CodeAndTest = 'Code & Test',
    Usage = 'Usage',
    Analytics = 'Analytics',
}

export const enum DataHubPageTab {
    ExperimentResults = 'experiment-results',
    DomainAggregates = 'domain-aggregates',
    Events = 'events',
}

export const enum DataHubNameTab {
    ExperimentResults = 'Experiment Results',
    Events = 'Events',
    DomainAggregates = 'Domain Aggregates',
}

export const enum AnalyticsPageTab {
    Dashboard = 'dashboard',
    Catalog = 'catalog',
}

export const enum AnalyticsNameTab {
    Dashboard = 'Views',
    Catalog = 'Catalog',
}

export const enum FavoriteExperimentAction {
    ADD = 'add',
    REMOVE = 'remove',
}

export const enum Color {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
    Info = 'info',
    Dark = 'dark',
    Light = 'light',
}

export const enum ProjectTab {
    Environments = 'environments',
    DomainTypes = 'domain-types',
}

export const enum PasswordToken {
    Valid = 'VALID',
    Invalid = 'INVALID',
    Empty = 'EMPTY',
}

export const enum GreenRedMarkColor {
    GREEN = 'GREEN',
    RED = 'RED',
}

export const enum DateTimeFormat {
    ViewDate = 'dd.MM.yyyy',
    ViewTime = 'dd.MM.yyyy HH:mm',
}

export enum CompanySize {
    GIGANT = 'GIGANT',
    BIG = 'BIG',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL',
    MICRO = 'MICRO',
}

export const enum Interest {
    OBSERVE = 'OBSERVE',
    COMPARE = 'COMPARE',
    SAFE_RELEASE = 'SAFE_RELEASE',
    TESTING = 'TESTING',
}

export enum ExperimentResultProps {
    DOMAIN_ID = 'DOMAIN_ID',
    VARIATION = 'VARIATION',
    EXPERIMENT = 'EXPERIMENT',
    DOMAIN_TYPE = 'DOMAIN_TYPE',
    LOCATION_TAG = 'LOCATION_TAG',
    HANDLED_VARIATIONS = 'HANDLED_VARIATIONS',
    TIMESTAMP = 'TIMESTAMP',
    SDK = 'SDK',
    SDK_VERSION = 'SDK_VERSION',
    MACHINE_IP = 'MACHINE_IP',
    ATTRIBUTES = 'ATTRIBUTES',
    VERSION = 'VERSION',
}

export enum EventProps {
    NAME = 'NAME',
    DOMAIN_TYPE = 'DOMAIN_TYPE',
    DOMAIN_ID = 'DOMAIN_ID',
    ROOT_TYPE = 'ROOT_TYPE',
    ROOT_ID = 'ROOT_ID',
    TIMESTAMP = 'TIMESTAMP',
    SDK = 'SDK',
    SDK_VERSION = 'SDK_VERSION',
    MACHINE_IP = 'MACHINE_IP',
    ATTRIBUTES = 'ATTRIBUTES',
}

export enum Count {
    COUNT = 'COUNT',
    CUMULATIVE_COUNT = 'CUMULATIVE_COUNT',
}

export enum PreviewTypes {
    LINE = 'LINE',
    TABLE = 'TABLE',
    NUMBER = 'NUMBER',
}

export enum CustomDateRange {
    YESTERDAY = 'YESTERDAY',
    TODAY = 'TODAY',
    THIS_WEEK = 'THIS_WEEK',
    THIS_MONTH = 'THIS_MONTH',
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_14_DAYS = 'LAST_14_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_48_HOURS = 'LAST_48_HOURS',
}
