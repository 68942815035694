import { lazy } from 'react';

export const AsyncProjects = lazy(() =>
    import('./Projects/Projects.component').then((module) => ({
        default: module.ProjectsComponent,
    }))
);

export const AsyncProject = lazy(() =>
    import('./Project/Project.component').then((module) => ({
        default: module.ProjectComponent,
    }))
);

export const AsyncApiKeys = lazy(() =>
    import('./APIKeys/ApiKeys.component').then((module) => ({
        default: module.ApiKeysComponent,
    }))
);

export const AsyncEnvironments = lazy(() =>
    import('./Environments/Environments.component').then((module) => ({
        default: module.EnvironmentsComponent,
    }))
);

export const AsyncDomainTypes = lazy(() =>
    import('./DomainTypes/DomainTypes.component').then((module) => ({
        default: module.DomainTypesComponent,
    }))
);

export const AsyncUsers = lazy(() =>
    import('./Users/Users.component').then((module) => ({
        default: module.UsersComponent,
    }))
);
