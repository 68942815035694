import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Container } from 'reactstrap';

import './yup/yupCustomMethods';
import { apolloClient } from 'config/apollo/apolloClient';
import { AuthContextProvider } from 'context/Auth.context';
import { OrganizationContextProvider } from 'context/Organization.context';
import { EnvironmentContextProvider } from 'context/Environments.context';
import { CustomRouteComponent } from 'components/CustomRoute/CustomRoute.component';
import { HeaderComponent } from 'components/Header/Header.component';

import './styles/global.scss';

const root = createRoot(document.getElementById('root'));

root.render(
    <ApolloProvider client={apolloClient}>
        <AuthContextProvider>
            <OrganizationContextProvider>
                <EnvironmentContextProvider>
                    <HashRouter>
                        <Container fluid className="h-100 d-flex flex-column overflow-auto">
                            <HeaderComponent />
                            <Suspense fallback={null}>
                                <CustomRouteComponent />
                            </Suspense>
                        </Container>
                    </HashRouter>
                </EnvironmentContextProvider>
            </OrganizationContextProvider>
        </AuthContextProvider>
    </ApolloProvider>
);
