import React, { useState, useCallback } from 'react';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchMoreQueryOptions } from '@apollo/client/core/watchQueryOptions';
import Pagination from 'rc-pagination';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { Postbacks, Postbacks_postbacks } from 'graphql/queries/generatedTypes/Postbacks';
import { PostbacksViewerSortInput, SearchPostbackInput } from 'graphql/generatedGlobal.typings';
import { ReactComponent as ChevronIcon } from 'assets/img/chevron-up.svg';

import styles from './Pagination.module.scss';
import 'rc-pagination/assets/index.less';

const quantityLimitItems = [10, 20, 50, 100];
const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE = 1;

interface PaginationComponentProps {
    setFieldValue: (string, unknown) => void;
    setQueryLimit: (number) => void;
    setQueryOffset: (number) => void;
    queryLimit: number;
    queryOffset: number;
    getData: (PostbacksVariables) => Promise<ApolloQueryResult<Postbacks>>;
    values: SearchPostbackInput;
    data: Postbacks_postbacks;
    querySort: PostbacksViewerSortInput[];
    fetchMore<TFetchData = Postbacks, TFetchVars = Record<string, Postbacks>>(
        fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
            updateQuery?: (
                previousQueryResult: Postbacks,
                options: {
                    fetchMoreResult: TFetchData;
                    variables: TFetchVars;
                }
            ) => Postbacks;
        }
    ): Promise<ApolloQueryResult<TFetchData>>;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
    setQueryOffset,
    setFieldValue,
    setQueryLimit,
    getData,
    queryOffset,
    queryLimit,
    querySort,
    values,
    data,
    fetchMore,
}) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);

    const toggleDropdown = useCallback(() => setIsOpenDropdown((prevState) => !prevState), [isOpenDropdown]);

    const onChange = useCallback(
        (pageNumber, limit) => {
            setCurrentPage(pageNumber);
            const offset = (pageNumber - 1) * limit;

            setQueryOffset(offset);
            setFieldValue('offset', offset);

            fetchMore({
                variables: {
                    input: {
                        environmentId: values?.environmentId,
                        filters: values?.filters?.filter(({ value }) => value),
                        sort: querySort,
                        limit,
                        offset,
                    },
                },
                updateQuery: (previous, { fetchMoreResult }) => {
                    if (!fetchMoreResult?.postbacks?.result) return previous;

                    return {
                        postbacks: {
                            ...fetchMoreResult.postbacks,
                            ...fetchMoreResult?.postbacks?.result,
                        },
                    };
                },
            });
        },
        [getData, values, querySort, queryLimit]
    );

    const renderSideButtons = useCallback(
        (current, type, element) => {
            if (type === 'prev') {
                return (
                    <Button className={styles.chevronBtn} disabled={!queryOffset} color="secondary" outline>
                        <ChevronIcon className={styles.chevronPrevIcon} />
                    </Button>
                );
            }
            if (type === 'next') {
                return (
                    <Button className={styles.chevronBtn} color="secondary" outline>
                        <ChevronIcon className={styles.chevronNextIcon} />
                    </Button>
                );
            }
            return element;
        },
        [currentPage, queryLimit, queryOffset]
    );

    return (
        <div className={styles.mainContainer}>
            <Pagination
                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total}`}
                className={styles.paginationContainer}
                total={data?.pageInfo?.totalCount}
                itemRender={renderSideButtons}
                defaultPageSize={queryLimit}
                onChange={onChange}
                jumpPrevIcon="..."
                jumpNextIcon="..."
            />

            <div className="d-flex align-items-center">
                <p className={styles.dropdownTitle}>Results per page:</p>

                <Dropdown isOpen={isOpenDropdown} toggle={toggleDropdown} direction="up">
                    <DropdownToggle className={styles.dropdownToggle} caret>
                        {queryLimit}
                    </DropdownToggle>
                    <DropdownMenu className={styles.dropdownMenu}>
                        {quantityLimitItems.map((limit: number) => (
                            <DropdownItem
                                onClick={() => {
                                    setQueryOffset(DEFAULT_OFFSET);
                                    setQueryLimit(limit);
                                    setFieldValue('offset', DEFAULT_OFFSET);
                                    setFieldValue('limit', limit);

                                    getData({
                                        variables: {
                                            input: {
                                                environmentId: values?.environmentId,
                                                filters: values?.filters?.filter(({ value }) => value),
                                                sort: querySort,
                                                offset: DEFAULT_OFFSET,
                                                limit,
                                            },
                                        },
                                        fetchPolicy: 'network-only',
                                    });
                                }}
                                key={limit}
                            >
                                {limit}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};
