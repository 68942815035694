import React, { useMemo } from 'react';

import { classNames } from 'helpers/classNames.helper';

import styles from './ValidationPasswordInfo.module.scss';

interface ValidationPasswordInfoComponentProps {
    input: string;
}

const isUpperCase = (input) => /[A-Z]/.test(input);
const isLowerCase = (input) => /[a-z]/.test(input);
const isNumbers = (input) => /\d/.test(input);
const isSpecialCharacters = (input) => /[_!@#$%^&*-]/.test(input);
const more1Character = (input) => input.length >= 8;
const less65Characters = (input) => input.length <= 64;

export const ValidationPasswordInfoComponent: React.FC<ValidationPasswordInfoComponentProps> = ({ input }) => {
    const validationPoints = useMemo(
        () => [
            {
                text: 'Lowercase Latin letters (a-z)',
                validationRule: isLowerCase,
            },
            {
                text: 'Uppercase Latin letters (A-Z)',
                validationRule: isUpperCase,
            },
            {
                text: 'Numbers (0-9)',
                validationRule: isNumbers,
            },
            {
                text: 'Some special characters (_-!@#$%^&*)',
                validationRule: isSpecialCharacters,
            },
            {
                text: 'At least 8 characters',
                validationRule: more1Character,
            },
            {
                text: 'Maximum 64 characters',
                validationRule: less65Characters,
            },
        ],
        [input]
    );

    return (
        <div className={styles.container}>
            <p>
                <b>Your password must contain:</b>
            </p>

            <ul className="list-unstyled mb-0">
                {validationPoints.map(({ text, validationRule }, index) => (
                    <li className="d-flex mb-2" key={index}>
                        <div
                            className={classNames({
                                [styles.passivePoint]: true,
                                [styles.active]: validationRule(input),
                            })}
                        />
                        <span>{text}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
