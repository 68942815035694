import { DocumentNode, gql } from '@apollo/client';

export const GET_FILE_ID: DocumentNode = gql`
    query ExportExperimentResults($input: ExperimentResultExportInput!) {
        exportExperimentResults(input: $input) {
            fileId
            fileStatus
        }
    }
`;
