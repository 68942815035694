import React, { FC } from 'react';
import { Col, Row, Spinner } from 'reactstrap';

import { Color } from 'enums.types';

interface PageSpinnerComponentProps {
    className?: string;
}

export const PageSpinnerComponent: FC<PageSpinnerComponentProps> = ({ className }) => (
    <Row className="m-0">
        <Col xs={12} className={`d-flex justify-content-center mt-5 ${className}`}>
            <Spinner color={Color.Dark} />
        </Col>
    </Row>
);
