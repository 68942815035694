import React from 'react';

import { Metric_metric_data } from 'graphql/queries/generatedTypes/Metric';
import { Preview_preview_data } from 'graphql/queries/generatedTypes/Preview';

interface NumberComponentProps {
    data: Metric_metric_data[] | Preview_preview_data[];
}

export const NumberComponent: React.FC<NumberComponentProps> = ({ data }) => (
    <p className="w-auto mx-2 my-4 fw-bolder">{data.reduce((sum, current) => sum + current.metricValue, 0)}</p>
);
