import { DocumentNode, gql } from '@apollo/client';

export const GET_DOMAIN_TYPES: DocumentNode = gql`
    query DomainTypes($projectId: String!) {
        domainTypes(projectId: $projectId) {
            id
            name
        }
    }
`;
