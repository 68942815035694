import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import { colors } from 'helpers/utils';
import { formatDateTime } from 'helpers/formatDateTime.helper';
import {
    WIDTH,
    MAX_WIDTH,
    HEIGHT,
    MAX_HEIGHT,
    DATETIME_LENGTH,
    MARGIN,
    TOOLTIP_STYLE,
    renderCustomTooltip,
    addStartDate,
} from './Graph.helpers';
import { Metric_metric_data } from 'graphql/queries/generatedTypes/Metric';
import { Preview_preview_data } from 'graphql/queries/generatedTypes/Preview';
import { MetricType } from 'graphql/generatedGlobal.typings';

interface GraphComponentProps {
    data: Metric_metric_data[] | Preview_preview_data[];
    type?: MetricType;
    isDashboard: boolean;
}

export const GraphComponent: React.FC<GraphComponentProps> = ({ data, type, isDashboard }) => (
    <AreaChart
        data={data.length > 1 ? data : addStartDate(data)}
        height={isDashboard ? HEIGHT : MAX_HEIGHT}
        width={isDashboard ? WIDTH : MAX_WIDTH}
        margin={MARGIN}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={(val) => formatDateTime(val?.date)?.slice(0, DATETIME_LENGTH)} interval="preserveStartEnd" />
        <YAxis interval="preserveStartEnd" type="number" />
        <Tooltip content={(v) => renderCustomTooltip(v, type)} itemStyle={TOOLTIP_STYLE} />
        <Area dataKey="metricValue" stroke={colors[0]} fill={colors[0]} legendType="line" />
    </AreaChart>
);
