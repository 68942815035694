import * as yup from 'yup';

// eslint-disable-next-line func-names
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    // eslint-disable-next-line func-names
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (this.parent.filter((v) => v !== value).some((v) => v[propertyName] === value[propertyName])) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.object, 'sumOfFields', function (propertyName, sum, message) {
    // eslint-disable-next-line func-names
    return this.test('sum', message, function (value) {
        if (!value) {
            return true;
        }

        const fieldSum = this.parent.reduce((acc, item) => acc + item[propertyName], 0);

        return Number(fieldSum?.toFixed(2)) === sum;
    });
});
