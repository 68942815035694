import React, { ReactNode, useMemo } from 'react';
import { Table } from 'reactstrap';

import { formatDateTime } from 'helpers/formatDateTime.helper';
import { getDictionary } from 'dictionary';
import { ExperimentResultProps } from 'enums.types';
import { TooltipComponent } from '../../Tooltip/Tooltip.component';
import { SortableThComponent } from '../../SortableTh/SortableTh.component';
import { AttributesModalComponent } from '../../AttributesModal/AttributesModal.component';
import { ExperimentResultSortField, ExperimentResultSortInput } from 'graphql/generatedGlobal.typings';
import { SearchExperimentResults_searchExperimentResults_result } from 'graphql/queries/generatedTypes/SearchExperimentResults';

import tableStyles from '../../Table/Table.module.scss';
import styles from '../ExperimentResult.module.scss';

const LS_SELECTED_COLUMNS = 'SELECTED_COLUMNS';
const LS_SELECTED_COLUMNS_DATA_HUB = 'SELECTED_COLUMNS_DATA_HUB';
const defaultChosenItems = [
    ExperimentResultProps.DOMAIN_ID,
    ExperimentResultProps.VARIATION,
    ExperimentResultProps.EXPERIMENT,
    ExperimentResultProps.DOMAIN_TYPE,
    ExperimentResultProps.TIMESTAMP,
];

interface ExperimentResultTableComponentProps {
    data: SearchExperimentResults_searchExperimentResults_result[];
    changeSortOrder: (ExperimentResultSortField) => void;
    currentSort: ExperimentResultSortInput;
    updateColumnsTrigger: boolean;
    isExperimentPage: boolean;
    isShowTable: boolean;
    children?: ReactNode;
}

export const ExperimentResultTableComponent: React.FC<ExperimentResultTableComponentProps> = ({
    updateColumnsTrigger,
    isExperimentPage,
    changeSortOrder,
    currentSort,
    isShowTable,
    data,
}) => {
    const selectedColumns = useMemo(
        () =>
            JSON.parse(localStorage.getItem(isExperimentPage ? LS_SELECTED_COLUMNS : LS_SELECTED_COLUMNS_DATA_HUB)) ||
            defaultChosenItems,
        [updateColumnsTrigger, isExperimentPage]
    );

    return (
        <Table cssModule={tableStyles} hover responsive>
            <thead>
                <tr>
                    {selectedColumns?.map((el) => (
                        <React.Fragment key={el}>
                            {[ExperimentResultProps.DOMAIN_ID, ExperimentResultProps.TIMESTAMP].includes(
                                el as ExperimentResultProps
                            ) ? (
                                <SortableThComponent
                                    onClick={(): void => changeSortOrder(ExperimentResultSortField[el])}
                                    active={currentSort?.field === ExperimentResultSortField[el]}
                                    direction={currentSort?.order}
                                >
                                    {getDictionary(el)}
                                </SortableThComponent>
                            ) : (
                                <th>{getDictionary(el)}</th>
                            )}
                        </React.Fragment>
                    ))}
                </tr>
            </thead>

            {isShowTable && data && (
                <tbody>
                    {data.map((el, index) => (
                        <tr key={index} className="align-middle">
                            {selectedColumns.includes(ExperimentResultProps.DOMAIN_ID) && (
                                <td className={styles.domainData}>
                                    {el?.domainId && <TooltipComponent id={el.domainId} />}
                                    <span>{el?.domainId || '-'}</span>
                                </td>
                            )}
                            {selectedColumns.includes(ExperimentResultProps.VARIATION) && <td>{el.variation}</td>}
                            {selectedColumns.includes(ExperimentResultProps.EXPERIMENT) && <td>{el.experiment}</td>}
                            {selectedColumns.includes(ExperimentResultProps.DOMAIN_TYPE) && <td>{el.domainName}</td>}
                            {selectedColumns.includes(ExperimentResultProps.LOCATION_TAG) && (
                                <td>{el.locationTag || '-'}</td>
                            )}
                            {selectedColumns.includes(ExperimentResultProps.HANDLED_VARIATIONS) && (
                                <td>
                                    {el.handledVariations?.length
                                        ? el.handledVariations.map((item, i, arr) => (
                                              <span>
                                                  {item}
                                                  {arr.length - 1 > i && ', '}
                                              </span>
                                          ))
                                        : '-'}
                                </td>
                            )}
                            {selectedColumns.includes(ExperimentResultProps.TIMESTAMP) && (
                                <td>{formatDateTime(el?.formattedTimestamp)}</td>
                            )}
                            {selectedColumns.includes(ExperimentResultProps.SDK) && <td>{el.sdk}</td>}
                            {selectedColumns.includes(ExperimentResultProps.SDK_VERSION) && (
                                <td>{el.sdkVersion || '-'}</td>
                            )}
                            {selectedColumns.includes(ExperimentResultProps.MACHINE_IP) && (
                                <td>{el.machineIp || '-'}</td>
                            )}
                            {selectedColumns.includes(ExperimentResultProps.ATTRIBUTES) && (
                                <td>
                                    {el.attributes && Object.keys(el.attributes).length ? (
                                        <AttributesModalComponent data={el.attributes} />
                                    ) : (
                                        '-'
                                    )}
                                </td>
                            )}
                            {selectedColumns.includes(ExperimentResultProps.VERSION) && (
                                <td className={styles.version}>{el.version || '-'}</td>
                            )}
                        </tr>
                    ))}
                </tbody>
            )}
        </Table>
    );
};
