import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';

import { deepCopyObject } from 'helpers/deepCopyObject.helper';
import { formatLocalISODateTime } from 'helpers/formatDateTime.helper';
import { Metric_metric_data } from 'graphql/queries/generatedTypes/Metric';
import { Preview_preview_data } from 'graphql/queries/generatedTypes/Preview';
import { MetricType } from 'graphql/generatedGlobal.typings';

const MS_PER_DAY = 1000 * 60 * 60 * 24;
export const WIDTH = 750;
export const MAX_WIDTH = 1600;
export const HEIGHT = 250;
export const MAX_HEIGHT = 350;
export const DATETIME_LENGTH = 10;
export const MARGIN = {
    top: 5,
    bottom: 5,
    right: 35,
    left: 0,
};
export const TOOLTIP_STYLE = { maxWidth: '20rem' };

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const renderCustomTooltip = (obj: TooltipProps<ValueType, NameType>, type: MetricType): JSX.Element => {
    const { active, payload = [], label } = obj;

    if (active && payload?.length) {
        return (
            <div className="mx-2">
                <p className="fw-bolder mb-0">{label}</p>
                <p className="mb-0">value: {payload[0]?.payload?.metricValue}</p>
                {type === MetricType.RETENTION && <p className="mb-0">first step: {payload[0]?.payload?.firstStep}</p>}
                {type === MetricType.RETENTION && (
                    <p className="mb-0">second step: {payload[0]?.payload?.secondStep}</p>
                )}
            </div>
        );
    }
    return null;
};

export const addStartDate = (arr: Metric_metric_data[] | Preview_preview_data[]): Metric_metric_data[] => {
    const copyArr = deepCopyObject(arr);
    const curDate = copyArr?.[0]?.date && new Date(copyArr?.[0]?.date);
    const prevDate = copyArr?.[0]?.date ? new Date(curDate.getTime() - 2 * MS_PER_DAY) : yesterday;

    copyArr.push({
        date: formatLocalISODateTime(prevDate),
        metricValue: 0,
        firstStep: null,
        secondStep: null,
        __typename: 'MetricDataValue',
    });

    return copyArr?.sort((a, b) => new Date(a?.date).valueOf() - new Date(b?.date).valueOf());
};
