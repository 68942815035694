import { DocumentNode, gql } from '@apollo/client';

export const DOWNLOAD_FILE: DocumentNode = gql`
    query DownloadExperimentResultsFile($fileId: String!) {
        downloadExperimentResultsFile(fileId: $fileId) {
            data
            fileName
        }
    }
`;
