import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Collapse } from 'reactstrap';

import { useAuthContext } from 'context/Auth.context';
import { useOrganizationContext } from 'context/Organization.context';
import { classNames } from 'helpers/classNames.helper';
import { getAvatarAlt } from 'helpers/avatarAlt.helper';
import { Paths } from 'enums.types';
import { LOGOUT } from 'api/Auth/Logout.mutation';
import { REST_CONTEXT } from 'config/apollo/apolloClient';
import { ReactComponent as DotsImg } from 'assets/img/three-dots-vertical.svg';
import { ReactComponent as ChevronIcon } from 'assets/img/chevron-up.svg';
import { ReactComponent as SettingsIcon } from 'assets/img/settings.svg';
import { ReactComponent as MarkIcon } from 'assets/img/mark.svg';
import { ReactComponent as ExitIcon } from 'assets/img/exit.svg';

import styles from './UserSettingsMenu.module.scss';

const LS_ENVIRONMENT_KEY = 'ENVIRONMENT';
const LS_POSTBACKS_FILTERS_KEY = 'POSTBACKS_FILTERS';

export const UserSettingsMenuComponent: React.FC = () => {
    const { user } = useAuthContext();
    const { organization, currentProject, setCurrentProject } = useOrganizationContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isProjectsOpen, setIsProjectsOpen] = useState<boolean>(false);

    const [logout] = useMutation(LOGOUT, {
        context: REST_CONTEXT,
    });

    const closeMenuClickHandler = useCallback(
        (e): void => {
            const userSettingsMenuContainer = document.getElementById('user-settings-menu');
            const target = e.target as HTMLElement;

            if (
                userSettingsMenuContainer &&
                !target.closest('#user-settings-menu') &&
                !target.closest('#user-settings-btn')
            ) {
                setIsOpen(false);
                setIsProjectsOpen(false);
            }
            e.stopPropagation();
        },
        [isOpen]
    );

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', closeMenuClickHandler);
        }
        return () => document.removeEventListener('click', closeMenuClickHandler);
    }, [isOpen]);

    return (
        <div className={styles.container}>
            <div
                onClick={() => !!organization && setIsOpen(!isOpen)}
                className={classNames({
                    [styles.toggleContainer]: true,
                    [styles.active]: isOpen,
                })}
                id="user-settings-btn"
            >
                <div className={styles.infoContainer}>
                    <div className={styles.avatarContainer}>{getAvatarAlt(organization?.name)}</div>
                    <div>
                        <p className={styles.name}>
                            <b>{organization?.name}</b>
                        </p>
                        <small className={styles.name}>{currentProject?.name}</small>
                    </div>
                </div>
                <DotsImg className={styles.dotsImg} />
            </div>

            {isOpen && (
                <div className={styles.popover} id="user-settings-menu">
                    <Link onClick={() => setIsOpen(false)} to={Paths.Settings} className={styles.popoverItem}>
                        <SettingsIcon className={styles.linkIcon} />
                        <b>Admin settings</b>
                    </Link>

                    <div className={`${styles.popoverItem} ${styles.popoverInfo}`}>
                        <div className={styles.avatarContainer}>{getAvatarAlt(currentProject?.name)}</div>
                        <div>
                            <b className={styles.name}>{currentProject?.name}</b>
                            <div>current project</div>
                        </div>
                    </div>

                    <div onClick={() => setIsProjectsOpen(!isProjectsOpen)} className={styles.popoverItem}>
                        <ChevronIcon
                            className={classNames({
                                [styles.icon]: true,
                                [styles.open]: isProjectsOpen,
                            })}
                        />
                        <b>Switch project</b>
                    </div>
                    <Collapse isOpen={isProjectsOpen} className={styles.collapseItems}>
                        {organization.projects.map((project) => (
                            <div
                                onClick={() => {
                                    setCurrentProject(project.id);
                                    delete localStorage[LS_POSTBACKS_FILTERS_KEY];
                                    localStorage.setItem(
                                        LS_ENVIRONMENT_KEY,
                                        JSON.stringify(project?.environments[0]?.id)
                                    );
                                    setIsOpen(false);
                                }}
                                className={styles.collapseItem}
                                key={project.id}
                            >
                                <span className={styles.name}>{project.name}</span>
                                {project.id === currentProject.id && <MarkIcon className={styles.markIcon} />}
                            </div>
                        ))}
                    </Collapse>

                    <div className={`${styles.popoverItem} ${styles.popoverInfo}`}>
                        <div className={styles.avatarContainer}>{getAvatarAlt(user?.email)}</div>
                        <div>
                            <b className={styles.name}>{user?.email}</b>
                        </div>
                    </div>

                    <Link className={styles.popoverItem} onClick={() => setIsOpen(false)} to={Paths.UserSettings}>
                        <b>User settings</b>
                    </Link>

                    <div
                        onClick={async () => {
                            await logout({ variables: { body: {} } });
                            window.location.reload();
                        }}
                        className={styles.popoverItem}
                    >
                        <ExitIcon className={styles.linkIcon} />
                        <b>Log out</b>
                    </div>
                </div>
            )}
        </div>
    );
};
