import React, { ReactElement, useMemo } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import styles from './Checkbox.module.scss';

export interface CheckboxComponentProps {
    id?: string;
    name?: string;
    value: boolean;
    disabled?: boolean;
    label?: string | ReactElement;
    onChange?: (value: boolean) => void;
    className?: string;
    classNameInput?: string;
}

const getUniqueKey = (item: boolean, index: boolean): string => `${item}_${new Date().getTime() + Number(index)}`;

export const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
    label,
    value,
    name,
    id = getUniqueKey(value, value),
    disabled,
    onChange,
    className,
    classNameInput,
}) => {
    const checkboxInput = useMemo(
        () => (
            <Input
                onChange={(e): void => onChange(e.target.checked)}
                className={`${styles.checkboxInput} ${classNameInput}`}
                disabled={disabled}
                checked={value}
                type="checkbox"
                name={name}
                id={id}
            />
        ),
        [disabled, value, name, id, onChange]
    );

    return (
        <FormGroup className="p-0" check>
            {label ? (
                <Label className={`${className || ''} ${styles.checkboxLabel} ${value ? styles.checked : ''}`} check>
                    {checkboxInput}
                    {label}
                </Label>
            ) : (
                checkboxInput
            )}
        </FormGroup>
    );
};
