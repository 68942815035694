import { DownloadExperimentResultsFile_downloadExperimentResultsFile } from 'graphql/queries/generatedTypes/DownloadExperimentResultsFile';

export const downloadFile = (input: DownloadExperimentResultsFile_downloadExperimentResultsFile): void => {
    const source = `data:application/zip;base64, ${input.data}`;
    const tempLink = document.createElement('a');

    tempLink.style.display = 'none';
    tempLink.href = source;
    tempLink.setAttribute('download', input.fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.parentNode.removeChild(tempLink);
};
