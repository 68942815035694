import { lazy } from 'react';

export const AsyncLogin = lazy(() =>
    import('./Login/LoginPage.component').then((module) => ({
        default: module.LoginPageComponent,
    }))
);
export const AsyncRecovery = lazy(() =>
    import('./Recovery/RecoveryPage.component').then((module) => ({
        default: module.RecoveryPageComponent,
    }))
);
export const AsyncInvite = lazy(() =>
    import('./Invite/InvitePage.component').then((module) => ({
        default: module.InvitePageComponent,
    }))
);
export const AsyncRegistration = lazy(() =>
    import('./Registration/RegistrationPage.component').then((module) => ({
        default: module.RegistrationPageComponent,
    }))
);
export const AsyncSettings = lazy(() =>
    import('./Settings/SettingsPage.component').then((module) => ({
        default: module.SettingsPageComponent,
    }))
);
export const AsyncUserSettings = lazy(() =>
    import('./UserSettings/UserSettingsPage.component').then((module) => ({
        default: module.UserSettingsPageComponent,
    }))
);
export const AsyncExperiments = lazy(() =>
    import('./Experiments/ExperimentsPage.component').then((module) => ({
        default: module.ExperimentsPageComponent,
    }))
);
export const AsyncDataHub = lazy(() =>
    import('./DataHub/DataHubPage.component').then((module) => ({
        default: module.DataHubPageComponent,
    }))
);
export const AsyncPostbacks = lazy(() =>
    import('./Postbacks/PostbacksPage.component').then((module) => ({
        default: module.PostbacksPageComponent,
    }))
);
export const AsyncPostback = lazy(() =>
    import('./Postback/PostbackPage.component').then((module) => ({
        default: module.PostbackPageComponent,
    }))
);
export const AsyncAnalytics = lazy(() =>
    import('./Analytics/AnalyticsPage.component').then((module) => ({
        default: module.AnalyticsPageComponent,
    }))
);
export const AsyncMetric = lazy(() =>
    import('./Metric/MetricPage.component').then((module) => ({
        default: module.MetricPageComponent,
    }))
);
