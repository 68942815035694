import { DocumentNode, gql } from '@apollo/client';

export const GET_SEARCH_EXPERIMENT_RESULTS: DocumentNode = gql`
    query SearchExperimentResults($input: ExperimentResultSearchInput!) {
        searchExperimentResults(input: $input) {
            result {
                domainId
                experiment
                experimentId
                machineIp
                machineName
                sdk
                sdkVersion
                formattedTimestamp
                variation
                version
                attributes
                handledVariations
                locationTag
                domainName
                environment {
                    name
                }
            }
            pageInfo {
                limit
                offset
                totalCount
            }
        }
    }
`;
