import * as yup from 'yup';

import { PostbackConditionValueInput } from 'graphql/generatedGlobal.typings';

const validateConditionValues = (conditionValues: PostbackConditionValueInput[], index: number): boolean => {
    // return false - error in condition
    // return true - it's correct condition

    // exception for NUMBER conditionTypes:
    if (conditionValues[index]?.numberValue === 0) {
        return true;
    }

    // exception for BOOLEAN conditionTypes:
    if (conditionValues[index]?.booleanValue === false) {
        return true;
    }

    return Object.values(conditionValues[index])?.some((el) => (Array.isArray(el) ? el.length : el));
};

export const validationSchema = yup.object({
    name: yup
        .string()
        .trim()
        .required('Required')
        .matches(
            /^[A-Za-z]+[A-Za-z0-9_,:&%-\\.\s]*$/,
            'The postback name must start with a letter and can contain "-_,.:&% a-z A-Z 0-9"'
        ),
    eventName: yup
        .string()
        .trim()
        .required('Required')
        .matches(
            /^[A-Za-z]+[A-Za-z0-9_,:&%-\\.\s]*$/,
            'The event name must start with a letter and can contain "-_,.:&% a-z A-Z 0-9"'
        ),
    url: yup.string().required('Required'),
    domainType: yup.string().trim().required('Required'),
    method: yup.string().trim().required('Required'),
    headers: yup.array().of(
        yup.object().shape({
            key: yup.string().required('Required'),
            value: yup.string().required('Required'),
        })
    ),
    conditions: yup.array().of(
        yup.object().shape({
            attributeName: yup
                .string()
                .trim()
                .nullable()
                .matches(
                    /^[A-Za-z]+[A-Za-z0-9_,:&%-\\.\s]*$/,
                    'The attribute name must start with a letter or number and can contain "-_,.:&% a-z A-Z 0-9"'
                ),
            conditionValues: yup.array().test('conditionValues', 'Required', (val) => validateConditionValues(val, 0)),
        })
    ),
});
