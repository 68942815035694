const MILLISECOND_PER_MINUTE = 60000;
const COUNT_SECOND_SYMBOLS = 8;

export const formatDateTime = (dateString: string): string => {
    if (!dateString) return '-';

    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    return date.toLocaleString('en-GB', options).replace(',', '');
};

export const formatLocalISODateTime = (date: Date = new Date()): string =>
    new Date(date.getTime() - date.getTimezoneOffset() * MILLISECOND_PER_MINUTE)
        .toISOString()
        .slice(0, -COUNT_SECOND_SYMBOLS);
