import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { ApolloQueryResult, useLazyQuery } from '@apollo/client';

import { useAuthContext } from './Auth.context';
import {
    Organization,
    Organization_organization,
    Organization_organization_projects,
    OrganizationVariables,
} from 'graphql/queries/generatedTypes/Organization';
import { GET_ORGANIZATION } from 'graphql/queries/getOrganization.query';

interface OrganizationContextState {
    organization: Organization_organization;
    currentProject: Organization_organization_projects;
    setCurrentProject: (id: string) => void;
    getOrganization?: () => Promise<ApolloQueryResult<Organization>>;
    refetch: () => void;
}

interface OrganizationContextProviderProps {
    children?: ReactNode;
}

const LS_PROJECT_KEY = 'PROJECT';

const OrganizationContext = createContext<OrganizationContextState>({
    organization: null,
    currentProject: null,
    getOrganization: null,
    setCurrentProject: () => null,
    refetch: () => null,
});

const getCurrentProject = (organization: Organization_organization): Organization_organization_projects => {
    if (!organization) {
        return null;
    }
    const savedProject: string = JSON.parse(localStorage.getItem(LS_PROJECT_KEY));
    const projectIndex = organization.projects.findIndex(({ id }) => id === savedProject);

    if (projectIndex >= 0) {
        return organization.projects[projectIndex];
    }
    localStorage.setItem(LS_PROJECT_KEY, JSON.stringify(organization.projects?.[0]?.id || null));

    return organization.projects[0];
};

export const useOrganizationContext = (): OrganizationContextState =>
    useContext<OrganizationContextState>(OrganizationContext);

export const OrganizationContextProvider: React.FC<OrganizationContextProviderProps> = ({ children }) => {
    const authContext = useAuthContext();

    const [currentProject, setCurrentProject] = useState<Organization_organization_projects>(null);

    const [getOrganization, { data, refetch }] = useLazyQuery<Organization, OrganizationVariables>(GET_ORGANIZATION, {
        variables: { id: authContext.user?.organizationId },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (authContext.isAuthenticated) {
            getOrganization();
        }
    }, [authContext]);

    useEffect(() => {
        if (data?.organization?.projects) {
            setCurrentProject(getCurrentProject(data.organization));
        }
    }, [data]);

    return (
        <OrganizationContext.Provider
            value={{
                currentProject,
                getOrganization,
                refetch,
                organization: data?.organization,
                setCurrentProject: (id: string) => {
                    localStorage.setItem(LS_PROJECT_KEY, JSON.stringify(id));
                    setCurrentProject(data.organization.projects.find((project) => project.id === id));
                },
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};
