import { initialize, pageview } from 'react-ga';

if (process.env.REACT_APP_ENV === 'prod') {
    initialize('UA-182961234-1');
}

export const sendPageviewToGA = (): void => {
    if (process.env.REACT_APP_ENV === 'prod') {
        pageview(window.location.href);
    }
};
