import { ApolloError, ServerError } from '@apollo/client';
import { getDictionary } from 'dictionary';

export const translateServerErrors = (errors: ApolloError): Record<string, string> =>
    errors.graphQLErrors.reduce((acc, error) => {
        const path = error?.extensions?.exception?.path;
        const message = error?.extensions?.exception?.message;

        if (path?.[0] === 'targetingRules') {
            const targetingRules = [];
            const conditions = [];

            conditions[path[3]] = {
                [path[4]]: getDictionary(message),
            };
            targetingRules[path[1]] = {
                [path[2]]: conditions,
            };
            acc[path[0]] = targetingRules;
        } else if (path && path?.[0] !== 'targetingRules') {
            acc[path[0]] = getDictionary(message);
        }
        return acc;
    }, {});

// for REST without GraphQL:
export const parseServerRestError = (errors: ServerError): Record<string, string> =>
    errors?.result?.reduce((acc, error) => {
        const path = error?.path?.[0];

        acc[path] = getDictionary(error?.message);
        return acc;
    }, {});
