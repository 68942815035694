export const LINK_TO_LENDING = 'https://reficio.io';

export const lendingRoutes = [
    {
        menuItem: 'Main',
        path: LINK_TO_LENDING,
    },
    {
        menuItem: 'Product',
        path: `${LINK_TO_LENDING}/#software`,
    },
    {
        menuItem: 'Industries',
        path: `${LINK_TO_LENDING}/#industries`,
    },
    {
        menuItem: 'Docs',
        path: `${LINK_TO_LENDING}/docs/`,
    },
];
