import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

export const REST_CONTEXT = {
    clientName: 'rest',
};

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
    }),
    link: ApolloLink.split(
        ({ getContext }) => getContext().clientName === REST_CONTEXT.clientName,
        new RestLink({ uri: '' }),
        new HttpLink({ uri: '/rio-client-gateway/proxy/rio-graphql-gateway/graphql' })
    ),
});
