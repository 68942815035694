import React, { useMemo } from 'react';
import { InputProps, FormFeedback } from 'reactstrap';

import { classNames } from 'helpers/classNames.helper';
import { conditionTypeOptions } from 'containers/Experiments/Experiment/ExperimentDefinition/TargetingRules/TargetingRule/TargetingRuleCondition/conditionTypeOptions.helper';
import { ReactComponent as ChevronIcon } from 'assets/img/chevron-up.svg';

import styles from './FakeInput.module.scss';

export interface FakeInputComponentProps extends InputProps {
    error?: string;
    value: string;
    attributeType?: string;
    type?: 'select' | 'text';
    isDisabled?: boolean;
}

export const FakeInputComponent: React.FC<FakeInputComponentProps> = ({
    type = 'text',
    attributeType,
    isDisabled,
    value,
    error,
}) => {
    const conditionTypeOption = useMemo(
        () => conditionTypeOptions?.find((el) => el.value.includes(attributeType)),
        [attributeType]
    );
    const result = useMemo(() => conditionTypeOption?.children?.find((el) => el.value === value), [value]);

    return (
        <>
            <div
                tabIndex={0}
                className={classNames({
                    'form-control': true,
                    [styles.input]: true,
                    [styles.select]: type === 'select',
                    [styles.disabled]: isDisabled,
                })}
            >
                {result?.label}
                {type === 'select' && <ChevronIcon className={styles.icon} />}
            </div>
            {error?.length && <FormFeedback valid={false}>{error}</FormFeedback>}
        </>
    );
};
