import React, { ReactNode } from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

interface SortableThComponentProps {
    direction: 'ASC' | 'DESC';
    onClick: () => void;
    active: boolean;
    children?: ReactNode;
}

export const SortableThComponent: React.FC<SortableThComponentProps> = ({ children, onClick, active, direction }) => (
    <th onClick={onClick}>
        <span>
            {active ? (
                {
                    ASC: <FaSortUp />,
                    DESC: <FaSortDown />,
                }[direction]
            ) : (
                <FaSort />
            )}
            <span>{children}</span>
        </span>
    </th>
);
