import React, { useState, useMemo, useEffect, ReactNode } from 'react';
import { useFormik } from 'formik';
import { Button, Popover, PopoverHeader, PopoverBody, Form } from 'reactstrap';

import { CheckboxComponent } from '../../../Checkbox/Checkbox.component';
import { ExperimentResultProps } from 'enums.types';
import { getDictionary } from 'dictionary';

import styles from './ControlTableColumnsModal.module.scss';

const LS_SELECTED_COLUMNS = 'SELECTED_COLUMNS';
const LS_SELECTED_COLUMNS_DATA_HUB = 'SELECTED_COLUMNS_DATA_HUB';

const defaultChosenItems = [
    ExperimentResultProps.DOMAIN_ID,
    ExperimentResultProps.VARIATION,
    ExperimentResultProps.EXPERIMENT,
    ExperimentResultProps.DOMAIN_TYPE,
    ExperimentResultProps.TIMESTAMP,
];

interface ControlTableColumnsModalComponentProps {
    setUpdateColumnsTrigger: (boolean) => void;
    updateColumnsTrigger: boolean;
    isExperimentPage: boolean;
    children: ReactNode;
}

export const ControlTableColumnsModalComponent: React.FC<ControlTableColumnsModalComponentProps> = ({
    setUpdateColumnsTrigger,
    updateColumnsTrigger,
    isExperimentPage,
    children,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggle = () => setIsOpen(!isOpen);

    const selectedColumns = useMemo(
        () => JSON.parse(localStorage.getItem(isExperimentPage ? LS_SELECTED_COLUMNS : LS_SELECTED_COLUMNS_DATA_HUB)),
        [isOpen, isExperimentPage]
    );

    const initExperimentResultValues = useMemo(
        () =>
            Object.keys(ExperimentResultProps).reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur]: selectedColumns
                        ? selectedColumns?.includes(cur)
                        : defaultChosenItems.includes(cur as ExperimentResultProps),
                }),
                {}
            ),
        [isOpen, isExperimentPage]
    );

    const { values, setFieldValue, resetForm, dirty } = useFormik({
        initialValues: {
            ...initExperimentResultValues,
        },
        onSubmit: () => {},
        enableReinitialize: true,
    });

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    return (
        <>
            <div id="controlTable">{children}</div>

            <Popover toggle={toggle} placement="right" target="controlTable" trigger="legacy" isOpen={isOpen}>
                <PopoverHeader>Show / hide columns</PopoverHeader>
                <PopoverBody className="pt-2">
                    <Form>
                        {Object.keys(ExperimentResultProps).map((el) => (
                            <CheckboxComponent
                                onChange={() => setFieldValue(el, !values[el])}
                                label={getDictionary(el)}
                                value={values[el]}
                                name={el}
                                key={el}
                            />
                        ))}

                        <div className={`pt-2 ${styles.containerButtons}`}>
                            <Button
                                className={styles.cancelBtn}
                                onClick={() => {
                                    setIsOpen(false);
                                    resetForm();
                                }}
                                disabled={!dirty}
                                color="secondary"
                                outline
                            >
                                Cancel
                            </Button>
                            <Button
                                className={styles.applyBtn}
                                onClick={() => {
                                    setIsOpen(false);
                                    const checkedItems = Object.keys(values)?.filter((el) => values[el]);
                                    localStorage.setItem(
                                        isExperimentPage ? LS_SELECTED_COLUMNS : LS_SELECTED_COLUMNS_DATA_HUB,
                                        JSON.stringify(checkedItems)
                                    );
                                    setUpdateColumnsTrigger(!updateColumnsTrigger);
                                }}
                                disabled={!dirty}
                                color="primary"
                                outline
                            >
                                Apply
                            </Button>
                        </div>
                    </Form>
                </PopoverBody>
            </Popover>
        </>
    );
};
