import { DocumentNode, gql } from '@apollo/client';

export const GET_ORGANIZATION: DocumentNode = gql`
    query Organization($id: String!) {
        organization(id: $id) {
            id
            name
            projects {
                id
                name
                environments {
                    id
                    name
                    production
                    projectId
                }
                domainTypes {
                    id
                    name
                    projectId
                    project {
                        id
                        name
                        organizationId
                    }
                }
            }
        }
    }
`;
