import React, { createContext, useContext, ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';

import { UserQuery } from 'api/Auth/User.query';
import { UserDto } from 'api/Auth/User.types';
import { REST_CONTEXT } from 'config/apollo/apolloClient';

interface AuthContextState {
    isAuthenticated: boolean;
    login: () => void;
    user: UserDto;
}

interface AuthContextProviderProps {
    children?: ReactNode;
}

const AuthContext = createContext<AuthContextState>({
    user: null,
    login: () => null,
    isAuthenticated: false,
});

export const useAuthContext = (): AuthContextState => useContext<AuthContextState>(AuthContext);

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const { data, refetch, loading } = useQuery<{ user: UserDto }>(UserQuery, {
        context: REST_CONTEXT,
    });

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: !!data?.user?.id,
                user: data?.user,
                login: refetch,
            }}
        >
            {!loading && children}
            {loading && <Spinner />}
        </AuthContext.Provider>
    );
};
