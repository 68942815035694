import React, { ReactNode } from 'react';
import { UncontrolledPopover } from 'reactstrap';

import styles from './PopoverMenu.module.scss';

export interface PopoverMenuItem {
    value: string | string[];
    label: string;
    children?: PopoverMenuItem[];
}

interface PopoverMenuComponentProps {
    options: PopoverMenuItem[];
    onSelect: (value: string) => void;
    id?: string;
    disabled?: boolean;
    children?: ReactNode;
}

const renderChildren = (onSelect, children: PopoverMenuItem[]) => (
    <div className={styles.itemContainer}>
        {children.map((child) => (
            <div key={child.label}>
                {child.children?.length && (
                    <>
                        <div id={child.label} className={styles.item}>
                            {child.label}
                        </div>
                        <UncontrolledPopover
                            target={child.label}
                            cssModule={styles}
                            placement="right"
                            trigger="hover"
                            offset={[0, 0]}
                        >
                            {renderChildren(onSelect, child.children)}
                        </UncontrolledPopover>
                    </>
                )}
                {!child.children?.length && (
                    <div onClick={() => onSelect(child.value)} className={styles.item}>
                        {child.label}
                    </div>
                )}
            </div>
        ))}
    </div>
);

export const PopoverMenuComponent: React.FC<PopoverMenuComponentProps> = ({
    disabled = false,
    options,
    children,
    onSelect,
    id,
}) => (
    <>
        <span id={`popover-${id}`}>{children}</span>
        <UncontrolledPopover
            target={`popover-${id}`}
            placement="bottom-start"
            disabled={disabled}
            cssModule={styles}
            offset={[0, 0]}
            trigger="focus"
        >
            {renderChildren(onSelect, options)}
        </UncontrolledPopover>
    </>
);
