export const showServerErrors = (serverErrors) => {
    const errors = serverErrors.reduce((acc, cur) => {
        if (cur.subject && cur.code) {
            const assign = (obj, keyPath, value) => {
                const lastKeyIndex = keyPath.length - 1;

                for (let i = 0; i < lastKeyIndex; ++i) {
                    const key = keyPath[i];

                    if (!(key in obj)) obj[key] = {};
                    obj = obj[key];
                }
                obj[keyPath[lastKeyIndex]] = value;
            };

            assign(acc, cur.subject.split('.'), cur.code);
        }

        return acc;
    }, {});
};

export const parseServerErrors = (errors) =>
    errors.map((error) => {
        const parsedError = error.message.match(/Exception while fetching data \(\/(\D+)\) : (.*)/);

        return {
            subject: parsedError[1],
            code: parsedError[2],
        };
    });

export const validateServerErrors = (errors) => showServerErrors(parseServerErrors(errors));

export const nearest = (value, min, max, steps) => {
    const multiplier = Math.round(((value - min) * steps) / (max - min)) / steps; // bring to 0-1 range

    return Math.round(multiplier * (max - min) + min);
};

export const convertToNumber = (value) => {
    if (!value) return value;
    const replaced = value.replace(/[^\d]/g, '');

    return replaced ? parseInt(replaced) : '';
};

export const normalizeNumber = (value) => {
    if (!value) return value;

    return value.replace(/[^\d.]/g, '');
};

export const convertToBoolean = (value) => {
    if (!value) return '';

    return JSON.parse(value);
};

export const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const checkDuplicate = (arr, property) => {
    for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
            if (arr[i][property] === arr[j][property]) return true;
        }
    }
    return false;
};

export const findErrorMessageForReactSelectInput = (err) => (Array.isArray(err) ? err?.find((el) => el) : err);

export const validationRuleForPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_!@#$%^&*-]).{8,64}$/;

export const validationRuleForEmail = /^[\w-.]{2,30}@([\w-]+\.)+[\w-]{2,4}$/;

export const colors = [
    '#8884d8',
    '#964b00',
    '#eb0edc',
    '#82caae',
    '#ffc658',
    '#577dfe',
    '#ff3f15',
    '#666b7d',
    '#24eb0e',
    '#cfdbf3',
    '#964b00',
    '#8b00ff',
];
