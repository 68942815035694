import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { InputComponent, InputComponentProps } from '../Input/Input.component';

export type MaskedInputComponentProps = NumberFormatProps & InputComponentProps;

export const MaskedInputComponent: React.FC<MaskedInputComponentProps> = (props) => (
    <NumberFormat {...props} customInput={InputComponent} />
);
