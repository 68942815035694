import { PostbackAttributeType, PostbackConditionType } from 'graphql/generatedGlobal.typings';

export const attributeTypeByConditionType: Record<PostbackConditionType, PostbackAttributeType> = {
    [PostbackConditionType.NOT_BETWEEN_NUMBER]: PostbackAttributeType.NUMBER,
    [PostbackConditionType.BETWEEN_NUMBER]: PostbackAttributeType.NUMBER,
    [PostbackConditionType.EQUAL_NUMBER]: PostbackAttributeType.NUMBER,
    [PostbackConditionType.NOT_EQUAL_NUMBER]: PostbackAttributeType.NUMBER,
    [PostbackConditionType.GREATER_THAN_OR_EQUAL_NUMBER]: PostbackAttributeType.NUMBER,
    [PostbackConditionType.LESS_THAN_OR_EQUAL_NUMBER]: PostbackAttributeType.NUMBER,

    [PostbackConditionType.CONTAINS_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.MATCHES_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.STARTS_WITH_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.NOT_MATCHES_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.NOT_STARTS_WITH_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.NOT_CONTAINS_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.NOT_ENDS_WITH_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.NOT_IN_LIST_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.IN_LIST_STRING]: PostbackAttributeType.STRING,
    [PostbackConditionType.ENDS_WITH_STRING]: PostbackAttributeType.STRING,

    [PostbackConditionType.BOOLEAN]: PostbackAttributeType.BOOLEAN,

    [PostbackConditionType.ON_DATE]: PostbackAttributeType.DATE,
    [PostbackConditionType.NOT_ON_DATE]: PostbackAttributeType.DATE,

    [PostbackConditionType.BETWEEN_DATE_TIME]: PostbackAttributeType.DATETIME,
    [PostbackConditionType.ON_OR_AFTER_DATE_TIME]: PostbackAttributeType.DATETIME,
    [PostbackConditionType.ON_OR_BEFORE_DATE_TIME]: PostbackAttributeType.DATETIME,
    [PostbackConditionType.NOT_BETWEEN_DATE_TIME]: PostbackAttributeType.DATETIME,

    [PostbackConditionType.PART_OF_SET]: PostbackAttributeType.SET,
    [PostbackConditionType.EQUAL_SET]: PostbackAttributeType.SET,
    [PostbackConditionType.NOT_PART_OF_SET]: PostbackAttributeType.SET,
    [PostbackConditionType.ALL_OF_SET]: PostbackAttributeType.SET,
    [PostbackConditionType.ANY_OF_SET]: PostbackAttributeType.SET,
    [PostbackConditionType.NOT_ALL_OF_SET]: PostbackAttributeType.SET,
    [PostbackConditionType.NOT_ANY_OF_SET]: PostbackAttributeType.SET,
    [PostbackConditionType.NOT_EQUAL_SET]: PostbackAttributeType.SET,
};

export const CONDITION_TYPES_WITH_TWO_INPUTS: PostbackConditionType[] = [
    PostbackConditionType.NOT_BETWEEN_DATE_TIME,
    PostbackConditionType.BETWEEN_DATE_TIME,
    PostbackConditionType.BETWEEN_NUMBER,
    PostbackConditionType.NOT_BETWEEN_NUMBER,
];
