/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AggregateType {
  AVERAGE = "AVERAGE",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
}

export enum ApiKeyType {
  ADMIN = "ADMIN",
  CLIENT_SIDE = "CLIENT_SIDE",
  SERVER_SIDE = "SERVER_SIDE",
}

export enum AttributeType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATETIME = "DATETIME",
  EXPERIMENT = "EXPERIMENT",
  NUMBER = "NUMBER",
  SET = "SET",
  STRING = "STRING",
}

export enum ConditionType {
  ALL_OF_SET = "ALL_OF_SET",
  ANY_OF_SET = "ANY_OF_SET",
  BETWEEN_DATE_TIME = "BETWEEN_DATE_TIME",
  BETWEEN_NUMBER = "BETWEEN_NUMBER",
  BOOLEAN = "BOOLEAN",
  CONTAINS_STRING = "CONTAINS_STRING",
  ENDS_WITH_STRING = "ENDS_WITH_STRING",
  EQUAL_NUMBER = "EQUAL_NUMBER",
  EQUAL_SET = "EQUAL_SET",
  GREATER_THAN_OR_EQUAL_NUMBER = "GREATER_THAN_OR_EQUAL_NUMBER",
  IN_EXPERIMENT = "IN_EXPERIMENT",
  IN_LIST_STRING = "IN_LIST_STRING",
  LESS_THAN_OR_EQUAL_NUMBER = "LESS_THAN_OR_EQUAL_NUMBER",
  MATCHES_STRING = "MATCHES_STRING",
  NOT_ALL_OF_SET = "NOT_ALL_OF_SET",
  NOT_ANY_OF_SET = "NOT_ANY_OF_SET",
  NOT_BETWEEN_DATE_TIME = "NOT_BETWEEN_DATE_TIME",
  NOT_BETWEEN_NUMBER = "NOT_BETWEEN_NUMBER",
  NOT_CONTAINS_STRING = "NOT_CONTAINS_STRING",
  NOT_ENDS_WITH_STRING = "NOT_ENDS_WITH_STRING",
  NOT_EQUAL_NUMBER = "NOT_EQUAL_NUMBER",
  NOT_EQUAL_SET = "NOT_EQUAL_SET",
  NOT_IN_EXPERIMENT = "NOT_IN_EXPERIMENT",
  NOT_IN_LIST_STRING = "NOT_IN_LIST_STRING",
  NOT_MATCHES_STRING = "NOT_MATCHES_STRING",
  NOT_ON_DATE = "NOT_ON_DATE",
  NOT_PART_OF_SET = "NOT_PART_OF_SET",
  NOT_STARTS_WITH_STRING = "NOT_STARTS_WITH_STRING",
  ON_DATE = "ON_DATE",
  ON_OR_AFTER_DATE_TIME = "ON_OR_AFTER_DATE_TIME",
  ON_OR_BEFORE_DATE_TIME = "ON_OR_BEFORE_DATE_TIME",
  PART_OF_SET = "PART_OF_SET",
  STARTS_WITH_STRING = "STARTS_WITH_STRING",
}

export enum DistributionType {
  DEFAULT_RULE = "DEFAULT_RULE",
  PERCENTAGE = "PERCENTAGE",
  VARIATION = "VARIATION",
}

export enum EntityType {
  API_KEY = "API_KEY",
  EXPERIMENT = "EXPERIMENT",
}

export enum EventsViewerFilterCategory {
  DATE_FROM = "DATE_FROM",
  DATE_TO = "DATE_TO",
  DOMAIN_ID = "DOMAIN_ID",
  DOMAIN_TYPE = "DOMAIN_TYPE",
  NAME = "NAME",
  ROOT_ID = "ROOT_ID",
  ROOT_TYPE = "ROOT_TYPE",
}

export enum EventsViewerSortField {
  TIMESTAMP = "TIMESTAMP",
}

export enum EventsViewerSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ExperimentChangeSortField {
  DATE = "DATE",
}

export enum ExperimentChangeSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ExperimentChangeType {
  ARCHIVE = "ARCHIVE",
  CREATE = "CREATE",
  DEF_ACTIVATE = "DEF_ACTIVATE",
  DEF_CREATE = "DEF_CREATE",
  DEF_KILL = "DEF_KILL",
  DEF_UPDATE = "DEF_UPDATE",
  RESTORE = "RESTORE",
}

export enum ExperimentResultFilterCategory {
  DATE_FROM = "DATE_FROM",
  DATE_TO = "DATE_TO",
  DOMAIN_ID = "DOMAIN_ID",
  DOMAIN_NAME = "DOMAIN_NAME",
  EXPERIMENT_NAME = "EXPERIMENT_NAME",
  LOCATION_TAG = "LOCATION_TAG",
  VARIATION = "VARIATION",
}

export enum ExperimentResultSortField {
  DOMAIN_ID = "DOMAIN_ID",
  TIMESTAMP = "TIMESTAMP",
}

export enum ExperimentResultSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ExperimentStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum FieldType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  DOUBLE = "DOUBLE",
  INT = "INT",
  OBJECT = "OBJECT",
  STRING = "STRING",
}

export enum GranularityType {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum HttpMethod {
  DELETE = "DELETE",
  GET = "GET",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  TRACE = "TRACE",
}

export enum MetricFilterType {
  HAS_DONE_EVENT = "HAS_DONE_EVENT",
}

export enum MetricOperator {
  CONTAINS = "CONTAINS",
  ENDS_WITH = "ENDS_WITH",
  EQUAL = "EQUAL",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  NOT_CONTAINS = "NOT_CONTAINS",
  NOT_EQUAL = "NOT_EQUAL",
  STARTS_WITH = "STARTS_WITH",
}

export enum MetricType {
  AGGREGATE = "AGGREGATE",
  AGGREGATE_PER_DOMAIN = "AGGREGATE_PER_DOMAIN",
  COUNT = "COUNT",
  COUNT_PER_DOMAIN = "COUNT_PER_DOMAIN",
  RETENTION = "RETENTION",
}

export enum MetricsViewerFilterCategory {
  DATE_FROM = "DATE_FROM",
  DATE_TO = "DATE_TO",
  DOMAIN_TYPE = "DOMAIN_TYPE",
  EVENT_NAME = "EVENT_NAME",
  METRIC_TYPE = "METRIC_TYPE",
  NAME = "NAME",
}

export enum MetricsViewerSortField {
  CREATED_AT = "CREATED_AT",
}

export enum MetricsViewerSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PeriodUnit {
  DAYS = "DAYS",
  MINUTES = "MINUTES",
  MONTHS = "MONTHS",
  WEEKS = "WEEKS",
}

export enum PostbackAttributeType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATETIME = "DATETIME",
  NUMBER = "NUMBER",
  SET = "SET",
  STRING = "STRING",
}

export enum PostbackConditionType {
  ALL_OF_SET = "ALL_OF_SET",
  ANY_OF_SET = "ANY_OF_SET",
  BETWEEN_DATE_TIME = "BETWEEN_DATE_TIME",
  BETWEEN_NUMBER = "BETWEEN_NUMBER",
  BOOLEAN = "BOOLEAN",
  CONTAINS_STRING = "CONTAINS_STRING",
  ENDS_WITH_STRING = "ENDS_WITH_STRING",
  EQUAL_NUMBER = "EQUAL_NUMBER",
  EQUAL_SET = "EQUAL_SET",
  GREATER_THAN_OR_EQUAL_NUMBER = "GREATER_THAN_OR_EQUAL_NUMBER",
  IN_LIST_STRING = "IN_LIST_STRING",
  LESS_THAN_OR_EQUAL_NUMBER = "LESS_THAN_OR_EQUAL_NUMBER",
  MATCHES_STRING = "MATCHES_STRING",
  NOT_ALL_OF_SET = "NOT_ALL_OF_SET",
  NOT_ANY_OF_SET = "NOT_ANY_OF_SET",
  NOT_BETWEEN_DATE_TIME = "NOT_BETWEEN_DATE_TIME",
  NOT_BETWEEN_NUMBER = "NOT_BETWEEN_NUMBER",
  NOT_CONTAINS_STRING = "NOT_CONTAINS_STRING",
  NOT_ENDS_WITH_STRING = "NOT_ENDS_WITH_STRING",
  NOT_EQUAL_NUMBER = "NOT_EQUAL_NUMBER",
  NOT_EQUAL_SET = "NOT_EQUAL_SET",
  NOT_IN_LIST_STRING = "NOT_IN_LIST_STRING",
  NOT_MATCHES_STRING = "NOT_MATCHES_STRING",
  NOT_ON_DATE = "NOT_ON_DATE",
  NOT_PART_OF_SET = "NOT_PART_OF_SET",
  NOT_STARTS_WITH_STRING = "NOT_STARTS_WITH_STRING",
  ON_DATE = "ON_DATE",
  ON_OR_AFTER_DATE_TIME = "ON_OR_AFTER_DATE_TIME",
  ON_OR_BEFORE_DATE_TIME = "ON_OR_BEFORE_DATE_TIME",
  PART_OF_SET = "PART_OF_SET",
  STARTS_WITH_STRING = "STARTS_WITH_STRING",
}

export enum PostbackStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum PostbackTrackViewerFilterCategory {
  DATE_FROM = "DATE_FROM",
  DATE_TO = "DATE_TO",
  DOMAIN_TYPE = "DOMAIN_TYPE",
  EVENT_ID = "EVENT_ID",
  EVENT_NAME = "EVENT_NAME",
  POSTBACK_ID = "POSTBACK_ID",
}

export enum PostbackTrackViewerSortField {
  VALUE_DATE = "VALUE_DATE",
}

export enum PostbacksViewerFilterCategory {
  DATE_FROM = "DATE_FROM",
  DATE_TO = "DATE_TO",
  DOMAIN_TYPE = "DOMAIN_TYPE",
  EVENT_NAME = "EVENT_NAME",
  METHOD = "METHOD",
  NAME = "NAME",
  STATUS = "STATUS",
}

export enum PostbacksViewerSortField {
  CREATED_AT = "CREATED_AT",
}

export enum PostbacksViewerSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UpsertType {
  ARRAY = "ARRAY",
  FIRST_ONLY = "FIRST_ONLY",
  INCREMENT = "INCREMENT",
  OVERWRITE = "OVERWRITE",
  SET = "SET",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  DELETED = "DELETED",
  PENDING = "PENDING",
}

export interface AggregateInput {
  attributeName?: string | null;
  type?: AggregateType | null;
}

export interface AggregateSchemaInput {
  attributes: AttributeItemInput[];
  domainType: string;
  environmentId: string;
  projectId: string;
  root?: RootIdInput | null;
}

export interface ArchiveExperimentInput {
  id: string;
  projectId: string;
}

export interface AttributeConditionInput {
  attributeName: string;
  attributeType: PostbackAttributeType;
  conditionType: PostbackConditionType;
  conditionValues: PostbackConditionValueInput[];
}

export interface AttributeItemInput {
  attributes?: AttributeItemInput[] | null;
  erasable?: boolean | null;
  isArray: boolean;
  name: string;
  type: FieldType;
  upsertType: UpsertType;
}

export interface BucketInput {
  size: number;
  variation: string;
}

export interface ConditionValueInput {
  booleanValue?: boolean | null;
  dateTimeValue?: LocalDateTime | null;
  dateValue?: LocalDate | null;
  experimentValue?: ExperimentValueInput | null;
  numberValue?: Long | null;
  setValue?: (string | null)[] | null;
  stringValue?: string | null;
}

export interface CopyDefinitionInput {
  experimentId: string;
  fromEnvironmentId: string;
  toEnvironmentId: string;
}

export interface CreateApiKeyInput {
  environmentId?: string | null;
  name: string;
  organizationId: string;
  projectId: string;
  type: ApiKeyType;
  userId?: string | null;
}

export interface CreateDomainTypeInput {
  name: string;
  projectId: string;
}

export interface CreateEnvironmentInput {
  name: string;
  production: boolean;
  projectId: string;
}

export interface CreateExperimentInput {
  description?: string | null;
  domainTypeId: string;
  name: string;
  projectId: string;
  reproducible: boolean;
}

export interface CreateMetricInput {
  aggregate?: AggregateInput | null;
  description?: string | null;
  domainType: string;
  environmentId: string;
  event: EventInput;
  name: string;
  retentionEvent?: EventInput | null;
  timeInterval?: TimeIntervalInput | null;
  timeRange?: TimeRangeInput | null;
  type: MetricType;
}

export interface CreatePostbackInput {
  activateDateFrom?: LocalDateTime | null;
  activateDateTo?: LocalDateTime | null;
  body?: string | null;
  comment?: string | null;
  conditions?: AttributeConditionInput[] | null;
  domainType: string;
  environmentId: string;
  eventName: string;
  headers?: HeaderInput[] | null;
  method: HttpMethod;
  name: string;
  projectId: string;
  status: PostbackStatus;
  url: string;
}

export interface CreateProjectInput {
  name: string;
  organizationId: string;
}

export interface DateRangeInput {
  endDate: LocalDate;
  startDate: LocalDate;
}

export interface EventInput {
  name: string;
  properties?: MetricPropertyInput[] | null;
}

export interface EventsViewerFilterInput {
  category: EventsViewerFilterCategory;
  value: string;
}

export interface EventsViewerSortInput {
  field: EventsViewerSortField;
  order: EventsViewerSortOrder;
}

export interface ExperimentChangeLogSearchInput {
  changeTypes: ExperimentChangeType[];
  environmentId: string;
  experimentId: string;
  limit?: number | null;
  offset?: Long | null;
  sort?: ExperimentChangeLogSortInput[] | null;
}

export interface ExperimentChangeLogSortInput {
  field: ExperimentChangeSortField;
  order: ExperimentChangeSortOrder;
}

export interface ExperimentDefinitionIdInput {
  environmentId: string;
  experimentId: string;
}

export interface ExperimentDefinitionInput {
  allocation: number;
  comment?: string | null;
  defaultRule: BucketInput[];
  defaultVariation: string;
  environmentId: string;
  experimentId: string;
  targetingRules?: (TargetingRuleInput | null)[] | null;
  variations: VariationInput[];
}

export interface ExperimentImpactInput {
  baseVariation: string;
  experimentName: string;
  metricId: string;
}

/**
 *  SEARCH METRIC INPUT
 */
export interface ExperimentImpactsInput {
  baseVariation: string;
  domainType: string;
  environmentId: string;
  experimentName: string;
}

export interface ExperimentResultCountInput {
  environmentId: string;
  experimentId: string;
  periodUnit: PeriodUnit;
}

export interface ExperimentResultExportInput {
  environmentId: string;
  filters: ExperimentResultFilterInput[];
  sort?: ExperimentResultSortInput[] | null;
}

export interface ExperimentResultFilterInput {
  category: ExperimentResultFilterCategory;
  value: string;
}

export interface ExperimentResultSearchInput {
  environmentId: string;
  filters: ExperimentResultFilterInput[];
  limit?: number | null;
  offset?: Long | null;
  sort?: ExperimentResultSortInput[] | null;
}

export interface ExperimentResultSortInput {
  field: ExperimentResultSortField;
  order: ExperimentResultSortOrder;
}

export interface ExperimentValueInput {
  experimentId: string;
  experimentName: string;
  selectedVariations: string[];
}

export interface ExperimentWithDependenciesInput {
  id: string;
  projectId: string;
}

export interface ExperimentWithMetricsInput {
  id: string;
  name: string;
  projectId: string;
}

export interface ExperimentsWithDefinitionInput {
  environmentId: string;
  highLoad: HighLoadInput;
  onlyFavorites: boolean;
  projectId: string;
  status?: ExperimentStatus | null;
}

export interface HeaderInput {
  key: string;
  value: string;
}

export interface HighLoadInput {
  period: Long;
  periodUnit: PeriodUnit;
}

export interface InviteUsersInput {
  emails: string[];
}

export interface MetricInput {
  metricId: string;
}

export interface MetricPropertyInput {
  attributeName: string;
  operator: MetricOperator;
  values: string[];
}

export interface MetricsViewerFilterInput {
  category: MetricsViewerFilterCategory;
  value: string;
}

export interface MetricsViewerSortInput {
  field: MetricsViewerSortField;
  order: MetricsViewerSortOrder;
}

export interface PostbackConditionValueInput {
  booleanValue?: boolean | null;
  dateTimeValue?: LocalDateTime | null;
  dateValue?: LocalDate | null;
  numberValue?: Long | null;
  setValue?: (string | null)[] | null;
  stringValue?: string | null;
}

export interface PostbackTrackViewerFilterInput {
  category: PostbackTrackViewerFilterCategory;
  value: string;
}

export interface PostbackTrackViewerSortInput {
  field: PostbackTrackViewerSortField;
  order: PostbacksViewerSortOrder;
}

export interface PostbacksViewerFilterInput {
  category: PostbacksViewerFilterCategory;
  value: string;
}

export interface PostbacksViewerSortInput {
  field: PostbacksViewerSortField;
  order: PostbacksViewerSortOrder;
}

export interface PreviewInput {
  aggregate?: AggregateInput | null;
  domainType: string;
  environmentId: string;
  event: EventInput;
  retentionEvent?: EventInput | null;
  timeInterval?: TimeIntervalInput | null;
  timeRange?: TimeRangeInput | null;
  type: MetricType;
}

export interface RestoreExperimentInput {
  id: string;
}

export interface RevokeApiKeyInput {
  id: string;
  userId?: string | null;
}

export interface RootIdInput {
  domainType: string;
  relationType: UpsertType;
}

export interface SearchAggregateSchemaInput {
  domainType: string;
  environmentId: string;
  projectId: string;
}

export interface SearchEventsInput {
  environmentId: string;
  filters: EventsViewerFilterInput[];
  limit?: number | null;
  offset?: Long | null;
  sort: EventsViewerSortInput[];
}

export interface SearchMetricsInput {
  environmentId: string;
  filters: MetricsViewerFilterInput[];
  limit?: number | null;
  offset?: Long | null;
  sort: MetricsViewerSortInput[];
}

export interface SearchPostbackInput {
  environmentId: string;
  filters: PostbacksViewerFilterInput[];
  limit?: number | null;
  offset?: Long | null;
  sort: PostbacksViewerSortInput[];
}

export interface SearchPostbackTrackInput {
  environmentId: string;
  filters: PostbackTrackViewerFilterInput[];
  limit?: number | null;
  offset?: Long | null;
  sort: PostbackTrackViewerSortInput[];
}

export interface TargetingRuleConditionInput {
  attributeName?: string | null;
  attributeType: AttributeType;
  conditionType: ConditionType;
  conditionValues: ConditionValueInput[];
}

export interface TargetingRuleInput {
  conditions: TargetingRuleConditionInput[];
  distribution: BucketInput[];
  distributionType: DistributionType;
}

/**
 *  RETENTION METRIC
 */
export interface TimeIntervalInput {
  granularity: GranularityType;
  period: number;
}

export interface TimeRangeInput {
  dateRange: DateRangeInput;
  granularity: GranularityType;
}

export interface UpdateEnvironmentInput {
  environmentId: string;
  name: string;
  production: boolean;
  projectId: string;
}

export interface UpdateExperimentInput {
  description?: string | null;
  id: string;
}

export interface UpdateFavoriteExperimentInput {
  experimentId: string;
  projectId: string;
  userId: string;
}

export interface UpdateMetricInput {
  aggregate?: AggregateInput | null;
  description?: string | null;
  domainType: string;
  environmentId: string;
  event: EventInput;
  id: string;
  name: string;
  retentionEvent?: EventInput | null;
  timeInterval?: TimeIntervalInput | null;
  timeRange?: TimeRangeInput | null;
  type: MetricType;
}

export interface UpdatePasswordInput {
  newPassword: string;
  oldPassword: string;
  userId: string;
}

export interface UpdatePostbackInput {
  activateDateFrom?: LocalDateTime | null;
  activateDateTo?: LocalDateTime | null;
  body?: string | null;
  comment?: string | null;
  conditions?: AttributeConditionInput[] | null;
  domainType: string;
  eventName: string;
  headers?: HeaderInput[] | null;
  id: string;
  method: HttpMethod;
  name: string;
  status: PostbackStatus;
  url: string;
}

export interface UpdateProjectInput {
  name: string;
  projectId: string;
}

export interface UpdateTimezoneInput {
  timezone: string;
  userId: string;
}

export interface VariationInput {
  configurations?: string | null;
  description?: string | null;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
