import React, { FC } from 'react';
import { Alert } from 'reactstrap';

interface ErrorOrNotFoundComponentProps {
    error?: {
        message: string;
    };
    className?: string;
    message?: string;
}

export const ErrorOrNotFoundComponent: FC<ErrorOrNotFoundComponentProps> = ({ error, className, message }) =>
    error && error.message ? (
        <Alert color="danger" className={className}>
            {error.message}
        </Alert>
    ) : (
        <div className={className}>
            <p>No data {message}</p>
        </div>
    );
