import {
    MetricPropertyInput,
    TimeIntervalInput,
    AggregateInput,
    TimeRangeInput,
    GranularityType,
    MetricOperator,
    AggregateType,
    EventInput,
    MetricType,
} from 'graphql/generatedGlobal.typings';
import { PreviewTypes } from 'enums.types';

export interface MetricInput {
    domainType: string;
    environmentId: string;
    name: string;
    description?: string;
    id?: string;
    type: MetricType;
    event: EventInput;
    retentionEvent?: EventInput;
    timeRange?: TimeRangeInput;
    timeInterval?: TimeIntervalInput;
    aggregate?: AggregateInput;
}

export interface PreviewTypesProps {
    name: string;
    type: PreviewTypes;
}

export const previewTypes: PreviewTypesProps[] = [
    {
        name: 'Line',
        type: PreviewTypes.LINE,
    },
    {
        name: 'Table',
        type: PreviewTypes.TABLE,
    },
    // {
    //     name: 'Number',
    //     type: PreviewTypes.NUMBER,
    // },
];

export const DEFAULT_TIME_RANGE: TimeRangeInput = {
    granularity: GranularityType.DAY,
    dateRange: {
        startDate: '2023-02-01T00:00',
        endDate: '2023-03-31T00:00',
    },
};

export const DEFAULT_ATTRIBUTE: AggregateInput = {
    attributeName: '',
    type: AggregateType.SUM,
};

export const DEFAULT_PROPERTY: MetricPropertyInput = {
    attributeName: '',
    operator: MetricOperator.CONTAINS,
    values: [],
};

export const DEFAULT_EVENT: EventInput = {
    name: '',
    properties: [],
};
