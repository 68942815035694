import React, { useMemo, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormikErrors } from 'formik';
import { Form, Button } from 'reactstrap';

import { useOrganizationContext } from 'context/Organization.context';
import { getDictionary } from 'dictionary';
import { deepCopyObject } from 'helpers/deepCopyObject.helper';
import { downloadFile } from 'helpers/downloadFile.helper';
import { formatLocalISODateTime } from 'helpers/formatDateTime.helper';
import { ErrorOrNotFoundComponent } from '../../ErrorOrNotFound/ErrorOrNotFound.component';
import { PageSpinnerComponent } from '../../PageSpinner/PageSpinner.component';
import { InputComponent } from '../../Input/Input.component';
import { DatePickerComponent } from '../../DatePicker/DatePicker.component';
import { ControlTableColumnsModalComponent } from './ControlTableColumnsModal/ControlTableColumnsModal.component';

import { GET_DOMAIN_TYPES } from 'graphql/queries/getDomainTypes.query';
import { GET_FILE_ID } from 'graphql/queries/getExportExperimentResults.query';
import { DOWNLOAD_FILE } from 'graphql/queries/getDownloadExperimentResultsFile.query';
import { DomainTypes, DomainTypesVariables } from 'graphql/queries/generatedTypes/DomainTypes';
import {
    ExportExperimentResults,
    ExportExperimentResultsVariables,
} from 'graphql/queries/generatedTypes/ExportExperimentResults';
import {
    DownloadExperimentResultsFile,
    DownloadExperimentResultsFileVariables,
} from 'graphql/queries/generatedTypes/DownloadExperimentResultsFile';
import {
    ExperimentResultFilterCategory,
    ExperimentResultFilterInput,
    ExperimentResultSearchInput,
    ExperimentResultSortInput,
} from 'graphql/generatedGlobal.typings';
import { ReactComponent as DeleteIcon } from 'assets/img/x-red.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/download.svg';

import styles from './ExperimentResultForm.module.scss';

interface ExperimentResultFormComponentProps {
    setFieldValue: (filters: string, res: ExperimentResultFilterInput[] | string) => void;
    setUpdateColumnsTrigger: (boolean) => void;
    setIsValidateOnChange: (boolean) => void;
    handleSubmit: () => void;
    errors: FormikErrors<ExperimentResultSearchInput>;
    downloadFilters: ExperimentResultFilterInput[];
    values: ExperimentResultSearchInput;
    sort: ExperimentResultSortInput[];
    updateColumnsTrigger: boolean;
    isExperimentPage: boolean;
    loading: boolean;
    isValid: boolean;
}

const DEFAULT_FILTER: ExperimentResultFilterInput = {
    value: '',
    category: undefined,
};
const filterCategories = [
    ExperimentResultFilterCategory.DATE_FROM,
    ExperimentResultFilterCategory.DATE_TO,
    ExperimentResultFilterCategory.DOMAIN_ID,
    ExperimentResultFilterCategory.VARIATION,
    ExperimentResultFilterCategory.LOCATION_TAG,
];
const dateFilterCategory = [ExperimentResultFilterCategory.DATE_FROM, ExperimentResultFilterCategory.DATE_TO];
const STATUS_DONE = 'done';

export const ExperimentResultFormComponent: React.FC<ExperimentResultFormComponentProps> = ({
    setUpdateColumnsTrigger,
    setIsValidateOnChange,
    updateColumnsTrigger,
    isExperimentPage,
    downloadFilters,
    setFieldValue,
    handleSubmit,
    loading,
    isValid,
    errors,
    values,
    sort,
}) => {
    const { currentProject } = useOrganizationContext();

    const [getDomainTypes, { data, error: errorDomainTypes, loading: loadingDomainTypes }] = useLazyQuery<
        DomainTypes,
        DomainTypesVariables
    >(GET_DOMAIN_TYPES, { variables: { projectId: currentProject?.id } });

    const [downloadFileByQuery, { loading: loadingDownloadExperimentResultsFile }] = useLazyQuery<
        DownloadExperimentResultsFile,
        DownloadExperimentResultsFileVariables
    >(DOWNLOAD_FILE, {
        onCompleted: async ({ downloadExperimentResultsFile }) => {
            if (downloadExperimentResultsFile?.data) {
                downloadFile(downloadExperimentResultsFile);
            }
        },
    });

    const [getFileID, { loading: loadingGetFile }] = useLazyQuery<
        ExportExperimentResults,
        ExportExperimentResultsVariables
    >(GET_FILE_ID, {
        onCompleted: ({ exportExperimentResults }) => {
            if (exportExperimentResults?.fileStatus === STATUS_DONE) {
                downloadFileByQuery({
                    variables: {
                        fileId: exportExperimentResults?.fileId,
                    },
                });
            }
        },
    });

    useEffect(() => {
        if (currentProject?.id && !isExperimentPage) {
            getDomainTypes();
        }
    }, [currentProject?.id, isExperimentPage]);

    const resultFilterCategory = useMemo(
        () => (isExperimentPage ? filterCategories : Object.keys(ExperimentResultFilterCategory)),
        [isExperimentPage]
    );

    const onInputChangeHandler = useCallback(
        (field: string, changedValue: string, index: number) => {
            const { filters } = deepCopyObject(values);

            filters[index] = {
                ...filters[index],
                [field]: changedValue,
                ...(field === 'category' && { value: '' }),
            };
            // check for empty default value for data categories:
            if (dateFilterCategory.includes(filters[index].category) && !filters[index].value) {
                filters[index].value = formatLocalISODateTime(new Date());
            }
            setFieldValue('filters', filters);
        },
        [values]
    );

    const onAddItemHandler = useCallback(() => {
        const { filters } = deepCopyObject(values);

        setFieldValue('filters', [...filters, DEFAULT_FILTER]);
    }, [values]);

    const deleteItemHandler = useCallback(
        (deletedIndex: number) => {
            const res = values.filters?.filter((el, i) => i !== deletedIndex);

            setFieldValue('filters', res);

            if (!res.length) {
                setIsValidateOnChange(false);
            }
        },
        [values, setIsValidateOnChange]
    );

    const onDownloadItemHandler = useCallback(() => {
        getFileID({
            variables: {
                input: {
                    environmentId: values.environmentId,
                    sort,
                    filters: downloadFilters,
                },
            },
        });
    }, [downloadFilters, values, sort]);

    return (
        <Form onSubmit={handleSubmit} className={styles.formContainer}>
            {values?.filters?.map((el, index) => (
                <div key={index} className={styles.criteriaContainer}>
                    <div className={styles.criteriaDescription}>{index === 0 ? 'Query by' : 'And'}</div>

                    <div className={styles.criteriaType}>
                        <InputComponent
                            onChange={(e) => onInputChangeHandler('category', e.target.value, index)}
                            error={errors?.filters?.[index]?.['category']}
                            value={values.filters?.[index].category}
                            name={`category-${index}`}
                            type="select"
                        >
                            <option value="">...</option>
                            {resultFilterCategory.map((item) => (
                                <option key={item} value={item}>
                                    {getDictionary(item)}
                                </option>
                            ))}
                        </InputComponent>
                    </div>

                    <div className={styles.criteriaValue}>
                        {dateFilterCategory.includes(values.filters?.[index]?.category) && (
                            <DatePickerComponent
                                className={styles.datePicker}
                                onChange={(date) => onInputChangeHandler('value', date, index)}
                                value={values.filters?.[index].value || formatLocalISODateTime(new Date())}
                                error={errors?.filters?.[index]?.['value']}
                                name={`value-${index}`}
                                showTimeSelect
                            />
                        )}

                        {values.filters?.[index]?.category === ExperimentResultFilterCategory.DOMAIN_NAME && (
                            <>
                                {loadingDomainTypes && <PageSpinnerComponent />}
                                {(errorDomainTypes || (data && !data?.domainTypes)) && (
                                    <ErrorOrNotFoundComponent error={errorDomainTypes} />
                                )}
                                <InputComponent
                                    onChange={(e) => onInputChangeHandler('value', e.target.value, index)}
                                    error={errors?.filters?.[index]?.['value']}
                                    value={values.filters?.[index].value}
                                    name={`value-${index}`}
                                    type="select"
                                >
                                    <option value="">...</option>
                                    {data?.domainTypes?.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </InputComponent>
                            </>
                        )}

                        {![ExperimentResultFilterCategory.DOMAIN_NAME, ...dateFilterCategory].includes(
                            values.filters?.[index]?.category
                        ) && (
                            <InputComponent
                                onChange={(e) => onInputChangeHandler('value', e.target.value, index)}
                                error={errors?.filters?.[index]?.['value']}
                                value={values.filters?.[index].value}
                                name={`value-${index}`}
                            />
                        )}

                        <Button onClick={() => deleteItemHandler(index)} color="link" outline>
                            <DeleteIcon />
                        </Button>
                    </div>
                </div>
            ))}

            <div className="d-flex">
                <div className="d-flex flex-grow-1">
                    <Button className={styles.actionBtn} onClick={onAddItemHandler} color="warning">
                        Add query criteria
                    </Button>
                </div>

                <div>
                    <div className="d-flex">
                        <Button
                            disabled={!values.environmentId || loading || !isValid}
                            className={styles.actionBtn}
                            color="warning"
                            type="submit"
                        >
                            <span>Query</span>
                        </Button>

                        <ControlTableColumnsModalComponent
                            setUpdateColumnsTrigger={setUpdateColumnsTrigger}
                            updateColumnsTrigger={updateColumnsTrigger}
                            isExperimentPage={isExperimentPage}
                        >
                            <Button color="primary" type="button" className={styles.actionBtn} outline>
                                <span>Columns</span>
                            </Button>
                        </ControlTableColumnsModalComponent>

                        {!!values.filters.length && (
                            <Button
                                onClick={() => setFieldValue('filters', [])}
                                className={styles.actionBtn}
                                color="danger"
                                type="button"
                                outline
                            >
                                <span>Clear all</span>
                            </Button>
                        )}

                        <Button
                            disabled={loading || !isValid || loadingGetFile || loadingDownloadExperimentResultsFile}
                            onClick={onDownloadItemHandler}
                            color="link"
                        >
                            <DownloadIcon />
                        </Button>
                    </div>
                </div>
            </div>
        </Form>
    );
};
