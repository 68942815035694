import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';

import { useOrganizationContext } from 'context/Organization.context';
import { useEnvironmentContext } from 'context/Environments.context';
import { translateServerErrors } from 'helpers/translateServerErrors.helper';
import { MaxLength } from 'validationSchema';
import { validationSchema } from './PostbackModal.validation';
import { PostbackModalProps, PostbackInput } from './PostbackModal.types';
import { InputComponent } from '../Input/Input.component';
import { ConditionComponent } from './Condition/Condition.component';
import { ActivityComponent } from './Activity/Activity.component';
import { RequestComponent } from './Request/Request.component';
import { CREATE_POSTBACK } from 'graphql/mutations/createPostback.mutation';
import { UPDATE_POSTBACK } from 'graphql/mutations/updatePostback.mutation';
import { HttpMethod, PostbackStatus } from 'graphql/generatedGlobal.typings';
import { CreatePostback } from 'graphql/mutations/generatedTypes/CreatePostback';
import { UpdatePostback } from 'graphql/mutations/generatedTypes/UpdatePostback';
import { ReactComponent as CloseIcon } from 'assets/img/x-lg.svg';

import modalStyles from 'components/Modal/Modal.module.scss';
import styles from './PostbackModal.module.scss';

export const PostbackModalComponent: React.FC<PostbackModalProps> = ({ data, isOpen, onClose }) => {
    const { currentProject } = useOrganizationContext();
    const { currentEnvironment } = useEnvironmentContext();

    const [isValidateOnChange, setIsValidateOnChange] = useState<boolean>(false);

    const [createPostback, { loading: loadingCreatePostback }] = useMutation<CreatePostback>(CREATE_POSTBACK);
    const [updatePostback, { loading: loadingUpdatePostback }] = useMutation<UpdatePostback>(UPDATE_POSTBACK);

    const {
        dirty,
        values,
        errors,
        touched,
        isValid,
        resetForm,
        setErrors,
        handleBlur,
        isSubmitting,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = useFormik<PostbackInput>({
        initialValues: {
            url: data?.url || '',
            name: data?.name || '',
            body: data?.body || '',
            comment: data?.comment || '',
            eventName: data?.eventName || '',
            domainType: data?.domainType || '',
            activateDateFrom: data?.activateDateFrom || '',
            activateDateTo: data?.activateDateTo || '',
            method: data?.method || HttpMethod.POST,
            headers: data?.headers?.length ? data?.headers : [],
            conditions: data?.conditions || [],
            status: data?.status || PostbackStatus.ENABLED,
            ...(!data && { environmentId: currentEnvironment?.id }),
        },
        onSubmit: (submittingValues: PostbackInput) => {
            const mutate = data ? updatePostback : createPostback;
            const response = data ? 'updatePostback' : 'createPostback';

            mutate({
                variables: {
                    input: {
                        ...submittingValues,
                        comment: submittingValues.comment || null,
                        body: submittingValues.body || null,
                        activateDateFrom: submittingValues.activateDateFrom || null,
                        activateDateTo: submittingValues.activateDateTo || null,
                        headers: submittingValues.headers.length ? submittingValues.headers : [],
                        conditions: submittingValues.conditions,
                        ...(!data && { environmentId: currentEnvironment?.id }),
                        ...(!data && { projectId: currentProject?.id }),
                        ...(data && { id: data?.id }),
                    },
                },
            })
                .then((resp) => {
                    if (resp.data?.[response]?.id) {
                        onClose();
                    }
                })
                .then(() => resetForm())
                .catch((err) => setErrors(translateServerErrors(err)));
        },
        enableReinitialize: true,
        validationSchema,
        validateOnChange: isValidateOnChange,
        validateOnBlur: isValidateOnChange,
    });

    useEffect(() => {
        // Add ability to remove error after submit before next submit action
        if (errors && isSubmitting) {
            setIsValidateOnChange(true);
        }
    }, [errors, isSubmitting]);

    const closeModalHandler = useCallback(() => {
        resetForm();
        setIsValidateOnChange(false);
        onClose();
    }, [onClose]);

    return (
        <Modal
            className={styles.modalContainer}
            cssModule={modalStyles}
            toggle={onClose}
            isOpen={isOpen}
            size="xl"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <ModalHeader cssModule={modalStyles}>
                    <b>{data ? 'Update' : 'Create'} webhook</b>
                    <CloseIcon className={modalStyles.closeButton} onClick={closeModalHandler} />
                </ModalHeader>

                <ModalBody cssModule={modalStyles}>
                    <FormGroup>
                        <Label for="name" className={modalStyles.label}>
                            Name *
                        </Label>
                        <InputComponent
                            error={touched.name && errors.name}
                            maxLength={MaxLength.Postback}
                            onChange={handleChange}
                            value={values.name}
                            onBlur={handleBlur}
                            name="name"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="comment" className={modalStyles.label}>
                            Description
                        </Label>
                        <InputComponent
                            error={touched.comment && errors.comment}
                            rows={values?.comment ? 3 : 1}
                            onChange={handleChange}
                            value={values.comment}
                            onBlur={handleBlur}
                            type="textarea"
                            name="comment"
                        />
                    </FormGroup>

                    <Row>
                        <b className="mt-3 mb-0 text-decoration-underline">Trigger:</b>
                        <Col xs={6}>
                            <Label for="domainType" className={modalStyles.label}>
                                Domain Type *
                            </Label>
                            <InputComponent
                                error={touched.domainType && errors.domainType}
                                value={values.domainType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="domainType"
                                type="select"
                            >
                                <option value="">...</option>
                                {currentProject?.domainTypes?.map(({ id, name }) => (
                                    <option key={id} value={name}>
                                        {name}
                                    </option>
                                ))}
                            </InputComponent>
                        </Col>

                        <Col xs={6}>
                            <Label for="eventName" className={modalStyles.label}>
                                Event *
                            </Label>
                            <InputComponent
                                error={touched.eventName && errors.eventName}
                                maxLength={MaxLength.Postback}
                                value={values.eventName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="eventName"
                            />
                        </Col>
                    </Row>

                    <ConditionComponent
                        onChange={(v) => setFieldValue('conditions', v)}
                        conditions={values?.conditions}
                        errors={errors?.conditions}
                    />

                    <RequestComponent
                        setIsValidateOnChange={setIsValidateOnChange}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        values={values}
                    />

                    <ActivityComponent
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        values={values}
                    />
                </ModalBody>

                <ModalFooter cssModule={modalStyles}>
                    <div className="d-flex justify-content-end">
                        <Button
                            disabled={loadingCreatePostback || loadingUpdatePostback}
                            onClick={closeModalHandler}
                            className={styles.btn}
                            color="secondary"
                        >
                            <b>Cancel</b>
                        </Button>
                        <Button
                            disabled={loadingCreatePostback || loadingUpdatePostback || !dirty || !isValid}
                            className={styles.btn}
                            color="warning"
                            type="submit"
                        >
                            <b>{data ? 'Update' : 'Create'}</b>
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
