import React from 'react';
import { Table } from 'reactstrap';

import { formatDateTime } from 'helpers/formatDateTime.helper';
import { Metric_metric_data } from 'graphql/queries/generatedTypes/Metric';
import { Preview_preview_data } from 'graphql/queries/generatedTypes/Preview';

import tableStyles from 'components/Table/Table.module.scss';

interface TableComponentProps {
    data: Metric_metric_data[] | Preview_preview_data[];
}

const DATETIME_LENGTH = 10;

export const TableComponent: React.FC<TableComponentProps> = ({ data }) => (
    <div className="w-50">
        <Table cssModule={tableStyles} hover responsive>
            <thead>
                <tr>
                    <th>Timestamp</th>
                    <th>Count</th>
                </tr>
            </thead>

            <tbody>
                {data?.map((el) => (
                    <tr key={el?.date}>
                        <td>{formatDateTime(el?.date)?.slice(0, DATETIME_LENGTH)}</td>
                        <td>{el?.metricValue}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
);
