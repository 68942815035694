import React from 'react';

import { convertToBoolean } from 'helpers/utils';
import { formatLocalISODateTime } from 'helpers/formatDateTime.helper';
import { DatePickerComponent } from '../../../../DatePicker/DatePicker.component';
import { InputComponent } from '../../../../Input/Input.component';
import { MaskedInputComponent } from '../../../../MaskedInput/MaskedInput.component';
import { TagInputComponent } from '../../../../TagInput/TagInput.component';
import { PostbackAttributeType, PostbackConditionValueInput } from 'graphql/generatedGlobal.typings';

interface ConditionValuesComponentProps {
    onChange: (value: PostbackConditionValueInput) => void;
    value: PostbackConditionValueInput;
    attributeType: PostbackAttributeType;
    isDisabled?: boolean;
    error?: unknown;
}

export const ConditionValuesComponent: React.FC<ConditionValuesComponentProps> = ({
    attributeType,
    isDisabled,
    onChange,
    value,
    error,
}) => (
    <>
        {[PostbackAttributeType.STRING, PostbackAttributeType.SET].includes(attributeType) && (
            <TagInputComponent
                onChange={(setValue) =>
                    onChange({
                        ...value,
                        setValue,
                    })
                }
                error={error?.['conditionValues']}
                value={value.setValue || []}
                isDisabled={isDisabled}
                name="conditionValues"
                placeholder="Type"
            />
        )}

        {attributeType === PostbackAttributeType.NUMBER && (
            <MaskedInputComponent
                onValueChange={({ floatValue }) =>
                    onChange({
                        ...value,
                        numberValue: floatValue,
                    })
                }
                error={error?.['conditionValues']}
                value={value.numberValue ?? ''}
                name="conditionValues"
                thousandSeparator=","
                decimalSeparator="."
            />
        )}

        {attributeType === PostbackAttributeType.DATE && (
            <DatePickerComponent
                value={value.dateValue || formatLocalISODateTime(new Date())}
                onChange={(date) =>
                    onChange({
                        ...value,
                        dateValue: date?.substring(0, 10),
                    })
                }
                error={error?.['conditionValues']}
                name="conditionValues"
                disabled={isDisabled}
            />
        )}

        {attributeType === PostbackAttributeType.DATETIME && (
            <DatePickerComponent
                value={value.dateTimeValue || formatLocalISODateTime(new Date())}
                onChange={(date) =>
                    onChange({
                        ...value,
                        dateTimeValue: date,
                    })
                }
                error={error?.['conditionValues']}
                name="conditionValues"
                disabled={isDisabled}
                showTimeSelect
            />
        )}

        {attributeType === PostbackAttributeType.BOOLEAN && (
            <InputComponent
                value={value.booleanValue !== null && value.booleanValue?.toString()}
                onChange={(e) =>
                    onChange({
                        ...value,
                        booleanValue: convertToBoolean(e.target.value),
                    })
                }
                name="conditionValues"
                type="select"
            >
                <option value="true">true</option>
                <option value="false">false</option>
            </InputComponent>
        )}
    </>
);
