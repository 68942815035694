import React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import styles from './PhoneInput.module.scss';

export interface PhoneInputComponentProps extends PhoneInputProps {
    error?: string;
    className?: string;
    containerClass?: string;
}

export const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({
    error,
    className,
    containerClass,
    ...props
}) => (
    <>
        <PhoneInput
            inputClass={`${styles.input} ${className || ''}`}
            containerClass={containerClass || ''}
            enableAreaCodes
            enableSearch
            {...props}
        />
        {error && <div className="invalid-feedback d-block">{error}</div>}
    </>
);
