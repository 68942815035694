import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

interface TagInputComponentProps {
    value: string[];
    onChange: (value: string[]) => void;
    placeholder?: string;
    name?: string;
    error?: string;
    isDisabled?: boolean;
}

interface TagInputOption {
    label: string;
    value: string;
}

const components = {
    DropdownIndicator: null,
};

const createOption = (label: string): TagInputOption => ({
    label,
    value: label,
});

export const TagInputComponent: React.FC<TagInputComponentProps> = ({
    placeholder,
    isDisabled,
    onChange,
    error,
    value,
    name,
}) => {
    const [inputValue, setInputValue] = useState<string>('');

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
        (event) => {
            if (!inputValue) return;
            switch (event.key) {
                case 'Enter':
                case 'Tab':
                    if (!value?.find((item) => item === inputValue)) {
                        onChange(value.length ? [...value, inputValue] : [inputValue]);
                        setInputValue('');
                    }
                    event.preventDefault();
            }
        },
        [value, inputValue]
    );

    return (
        <>
            <CreatableSelect
                onChange={(e) => onChange(e.map((el) => el.value))}
                value={value.map(createOption)}
                onInputChange={setInputValue}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                components={components}
                inputValue={inputValue}
                isDisabled={isDisabled}
                menuIsOpen={false}
                name={name}
                isClearable
                isMulti
            />
            {error && <div className="invalid-feedback d-block">{error}</div>}
        </>
    );
};
