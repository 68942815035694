import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Button, Row } from 'reactstrap';

import { routes } from 'routes/routes';
import { useAuthContext } from 'context/Auth.context';
import { useEnvironmentContext } from 'context/Environments.context';
import { Paths } from 'enums.types';
import { lendingRoutes, LINK_TO_LENDING } from './Header.helpers';
import { InputComponent } from 'components';
import { UserSettingsMenuComponent } from './UserSettingsMenu/UserSettingsMenu.component';
import { MobileMenuModalComponent } from './MobileMenuModal/MobileMenuModal.component';
import { ReactComponent as LogoImg } from 'assets/img/logo.svg';

import styles from './Header.module.scss';

const LS_POSTBACKS_FILTERS_KEY = 'POSTBACKS_FILTERS';
const LS_ENVIRONMENT_KEY = 'ENVIRONMENT';

export const HeaderComponent: React.FC = () => {
    const { isAuthenticated } = useAuthContext();
    const { getEnvironments, environments, currentEnvironment, setCurrentEnvironment } = useEnvironmentContext();

    const location = useLocation();
    const { projectId } = useParams<{ projectId: string }>();
    const navigate = useNavigate();

    const [pathname, setPathname] = useState<Paths | string>(null);
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);

    useEffect(() => {
        setPathname(location?.pathname);
    }, [projectId, location?.pathname]);

    return (
        <Row className={styles.container}>
            <div className={styles.logoContainer}>
                <a href={LINK_TO_LENDING}>
                    <LogoImg className={styles.logoImg} />
                </a>
            </div>

            {isAuthenticated ? (
                <>
                    <ul className={styles.navContainer}>
                        {routes
                            .filter(({ menuItem }) => menuItem)
                            .map(({ path, menuItem, icon }) => (
                                <li
                                    className={pathname?.includes(path) ? styles.active : styles.menuItem}
                                    key={menuItem}
                                >
                                    <Link
                                        onClick={() => {
                                            getEnvironments();
                                            delete localStorage[LS_POSTBACKS_FILTERS_KEY];
                                        }}
                                        className={styles.link}
                                        to={path}
                                    >
                                        <>
                                            {icon}
                                            <span className={styles.linkText}>{menuItem}</span>
                                        </>
                                    </Link>
                                </li>
                            ))}
                    </ul>

                    <div className={styles.envContainer}>
                        <span className={styles.envDescription}>Environment</span>
                        <InputComponent
                            onChange={(e) => {
                                setCurrentEnvironment(e.target.value);
                                localStorage.setItem(LS_ENVIRONMENT_KEY, JSON.stringify(e.target.value));
                            }}
                            value={currentEnvironment?.id}
                            className={styles.input}
                            name="environmentId"
                            type="select"
                        >
                            <option value="">...</option>
                            {environments?.map(({ name, id }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </InputComponent>
                    </div>

                    <div className={styles.settingsMenu}>
                        <UserSettingsMenuComponent />
                    </div>
                </>
            ) : (
                <>
                    <ul className={styles.navLendingContainer}>
                        {lendingRoutes.map(({ path, menuItem }) => (
                            <li className={styles.menuItem} key={menuItem}>
                                <a href={path} className={`${styles.link} ${styles.linkLending}`}>
                                    <span className={styles.linkText}>{menuItem}</span>
                                </a>
                            </li>
                        ))}
                    </ul>

                    <Button
                        onClick={() => navigate(pathname === Paths.Registration ? Paths.Login : Paths.Registration)}
                        className={pathname === Paths.Login ? styles.registrationBtn : styles.loginBtn}
                        color={pathname === Paths.Login ? 'primary' : 'secondary'}
                        outline={pathname === Paths.Registration}
                    >
                        {pathname === Paths.Login ? 'Sign up' : 'Login'}
                    </Button>

                    <MobileMenuModalComponent isOpen={isOpenMobileMenu} setIsOpen={setIsOpenMobileMenu} />
                </>
            )}
        </Row>
    );
};
