import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import { useAuthContext } from 'context/Auth.context';
import { Paths } from 'enums.types';
import { sendPageviewToGA } from 'helpers/ga.helper';
import { routes } from 'routes/routes';
import { AsyncLogin, AsyncExperiments, AsyncSettings, AsyncDataHub, AsyncAnalytics } from '../../containers';
import { settingsPageRoutes } from '../../containers/Settings/SettingsPage.routes';

const loggedRoutes = [
    Paths.UserSettings,
    Paths.Experiments,
    Paths.Webhooks,
    Paths.Analytics,
    Paths.Settings,
    Paths.DataHub,
    Paths.Metric,
];

export const CustomRouteComponent: React.FC = () => {
    const { isAuthenticated } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();

    const isLoggedRoute = useMemo(
        () => loggedRoutes.some((route) => location?.pathname?.includes(route)),
        [location?.pathname]
    );

    useEffect(() => {
        sendPageviewToGA();
    }, []);

    useEffect(() => {
        if (isLoggedRoute && !isAuthenticated) {
            navigate(Paths.Login, { replace: true });
        }
    }, [isLoggedRoute, isAuthenticated]);

    useEffect(() => {
        if (location?.pathname === '/') {
            navigate(Paths.Login, { replace: true });
        }
    }, [location?.pathname]);

    return (
        <Routes>
            {routes.map(({ path, element, nestedPath }) => (
                <Route key={path} path={path} element={element}>
                    {nestedPath && <Route path={nestedPath} element={element} />}
                </Route>
            ))}

            <Route path={Paths.Experiments} element={<AsyncExperiments />}>
                <Route path=":environmentId" element={<AsyncExperiments />}>
                    <Route path=":experimentId" element={<AsyncExperiments />}>
                        <Route path=":tabId" element={<AsyncExperiments />} />
                    </Route>
                </Route>
            </Route>

            <Route path={Paths.DataHub} element={<AsyncDataHub />}>
                <Route path=":tabId" element={<AsyncDataHub />} />
            </Route>

            <Route path={Paths.Analytics} element={<AsyncAnalytics />}>
                <Route path=":tabId" element={<AsyncAnalytics />} />
            </Route>

            <Route path={`${Paths.Settings}/*`} element={<AsyncSettings />}>
                {settingsPageRoutes.map(({ path, element, icon }) => (
                    <Route key={path} path={path} element={element}>
                        {!icon && (
                            <Route path=":projectId" element={element}>
                                <Route path=":tabType" element={element} />
                            </Route>
                        )}
                    </Route>
                ))}
            </Route>

            <Route path="*" element={<AsyncLogin />} />
        </Routes>
    );
};
