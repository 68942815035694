import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { AnalyticsPageTab, PreviewTypes } from 'enums.types';
import { GraphComponent } from './Graph/Graph.component';
import { TableComponent } from './Table/Table.component';
import { NumberComponent } from './Number/Number.component';
import { RetentionTableComponent } from './RetentionTable/RetentionTable.component';
import { previewTypes, PreviewTypesProps } from 'containers/Metric/MetricPage.helpers';
import { Metric_metric_data } from 'graphql/queries/generatedTypes/Metric';
import { Preview_preview_data } from 'graphql/queries/generatedTypes/Preview';
import { MetricType } from 'graphql/generatedGlobal.typings';

import styles from './GraphTableItems.module.scss';

interface GraphTableItemsProps {
    data: Metric_metric_data[] | Preview_preview_data[];
    type: MetricType;
    retentionEvent?: string;
    event?: string;
    loading: boolean;
}

export const GraphTableItemsComponent: React.FC<GraphTableItemsProps> = ({
    retentionEvent,
    loading,
    event,
    data,
    type,
}) => {
    const { tabId } = useParams<{ tabId: AnalyticsPageTab | string }>();

    const [typePreview, setTypePreview] = useState<PreviewTypesProps>(previewTypes[0]);

    const isDashboard = useMemo((): boolean => tabId === AnalyticsPageTab.Dashboard, [tabId]);

    return (
        <div className={isDashboard ? styles.container : 'w-100 px-4 mx-2'}>
            {!loading && !!data?.length && (
                <Row>
                    <UncontrolledDropdown>
                        <DropdownToggle caret>{typePreview.name}</DropdownToggle>
                        <DropdownMenu>
                            {previewTypes.map((el) => (
                                <DropdownItem key={el.type} onClick={() => setTypePreview(el)}>
                                    {el.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Row>
            )}

            {!loading && !!data?.length && (
                <Row className="my-3">
                    {typePreview.type === PreviewTypes.LINE && (
                        <GraphComponent data={data} type={type} isDashboard={isDashboard} />
                    )}
                    {typePreview.type === PreviewTypes.TABLE && <TableComponent data={data} />}
                    {typePreview.type === PreviewTypes.NUMBER && <NumberComponent data={data} />}
                </Row>
            )}

            {!loading && !!data?.length && !isDashboard && type === MetricType.RETENTION && (
                <Row className={styles.retentionTableContainer}>
                    <RetentionTableComponent data={data} retentionEvent={retentionEvent} event={event} />
                </Row>
            )}
        </div>
    );
};
