import {
    PostbackAttributeType,
    PostbackConditionType,
    AttributeConditionInput,
    PostbackConditionValueInput,
} from 'graphql/generatedGlobal.typings';

export const DEFAULT_VALUES: PostbackConditionValueInput = {
    booleanValue: null,
    stringValue: null,
    numberValue: null,
    setValue: null,
    dateTimeValue: null,
    dateValue: null,
};

export const DEFAULT_CONDITION: AttributeConditionInput = {
    attributeName: '',
    attributeType: PostbackAttributeType.STRING,
    conditionType: PostbackConditionType.STARTS_WITH_STRING,
    conditionValues: [DEFAULT_VALUES],
};
