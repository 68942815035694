import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { DateTimeFormat } from 'enums.types';
import { MaskedInputComponent, MaskedInputComponentProps } from '../MaskedInput/MaskedInput.component';
import { formatLocalISODateTime } from 'helpers/formatDateTime.helper';
import { ReactComponent as CalendarIcon } from 'assets/img/calendar3.svg';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePicker.module.scss';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const DateInput = forwardRef<MaskedInputComponentProps, MaskedInputComponentProps>((props, ref) => (
    <>
        <MaskedInputComponent {...props} />
        {!props.disabled && (
            <div onClick={props.onClick} className={styles.iconContainer}>
                <CalendarIcon className={styles.icon} />
            </div>
        )}
    </>
));

interface DatePickerComponentProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    minDate?: Date;
    maxDate?: Date;
    error?: string;
    className?: string;
    value: string;
    invalid?: boolean;
    showTimeSelect?: boolean;
    disabled?: boolean;
    onChange: (value: string) => void;
    filterTime?: (time: Date) => boolean | Date;
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
    value,
    invalid,
    maxDate,
    minDate,
    disabled,
    onChange,
    className,
    filterTime,
    showTimeSelect,
    ...props
}) => {
    const [innerDate, setInnerDate] = useState<Date>(value ? new Date(value) : null);

    useEffect(() => {
        setInnerDate(new Date(value));
    }, [value]);

    return (
        <DatePicker
            customInput={
                <DateInput
                    {...props}
                    placeholder={showTimeSelect ? 'DD.MM.YYYY hh:mm' : 'DD.MM.YYYY'}
                    className={`${className || ''}${invalid ? 'is-invalid' : ''}`}
                    format={showTimeSelect ? '##.##.#### ##:##' : '##.##.####'}
                    mask="_"
                />
            }
            onChange={(date: Date) => onChange(date ? formatLocalISODateTime(date) : '')}
            dateFormat={showTimeSelect ? DateTimeFormat.ViewTime : DateTimeFormat.ViewDate}
            calendarClassName={styles.calendar}
            showTimeSelect={showTimeSelect}
            selected={innerDate || value}
            filterTime={filterTime}
            showPopperArrow={false}
            calendarStartDay={1}
            disabled={disabled}
            timeIntervals={15}
            maxDate={maxDate}
            minDate={minDate}
        />
    );
};
